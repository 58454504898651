import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers, FormikValues } from 'formik'
import * as yup from 'yup'
import {
  Checkbox,
  Form,
  Icon,
  Modal,
  Button as SemanticButton,
} from 'semantic-ui-react'
import {
  ISponsorshipCreateForm,
  createSponsorship,
} from '../../../../redux/actions'
import { RootState } from '../../../../redux/reducer'
import { FormContainer } from './FormContainer'
import { ErrorMsg } from './ErrorMsgContainer'
import { SuccessContainer } from './SuccessContainer'
import { Input, CurrencyInput, Button, BraintreeDropIn } from '../../components'
import { ITheme } from '../../../../@types'

// form validationSchema
const validationSchema = () =>
  yup.object().shape({
    name: yup.string().label('Name').required('Please enter name'),
    email: yup
      .string()
      .label('Email')
      .required('Please enter email address')
      .email('Please enter valid email address'),
    amount: yup.number().label('Email').required('Please enter amount'),
  })

interface IFormContainer {
  theme: ITheme
}

interface ISponsorshipForm extends FormikValues {
  name: string
  email: string
  amount: number | undefined
}

const SponsorshipComponent: FC<IFormContainer> = (props) => {
  const { theme } = props
  const [success, setSuccess] = useState(false)
  const [braintree, setBraintree] = useState(null)
  const [modal, setModal] = useState(false)
  const [terms, setTerms] = useState(false)

  // sponsorship amount
  const [amount, setAmount] = useState(0)
  const [validated, setValidated] = useState(false)

  const loading = useSelector((state: RootState) => state.sponsorship.loading)
  const error = useSelector((state: RootState) => state.sponsorship.error)
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      braintree.clearSelectedPaymentMethod()
      setValidated(false)
    }
  }, [error])

  const formSubmit = async (
    form: ISponsorshipForm,
    formikHelper: FormikHelpers<ISponsorshipForm>
  ) => {
    // Send the nonce to your server
    const { nonce } = await braintree.requestPaymentMethod()

    // set donation amount
    if (form.amount) {
      setAmount(form.amount)
    }

    // check amount validation
    if (!validated) {
      setModal(true)
      formikHelper.setSubmitting(false)
      return
    }

    const params: ISponsorshipCreateForm = {
      name: form.name,
      email: form.email,
      amount: form.amount,
      token: nonce,
    }
    await dispatch(createSponsorship(params))
    formikHelper.resetForm({})
    setSuccess(true)
  }

  return (
    <div>
      {success ? (
        <SuccessContainer
          containerMarginTop="60px"
          title={
            <div>
              🙌🏻 Thank you for your<br />sponsorship!
            </div>
          }
          description={
            'We greatly appreciate your sponsorship of this event. As one of our valued sponsors, we are committed to providing your organization maximum visibility throughout. Welcome aboard!'
          }
        />
      ) : (
        <FormContainer
          containerPaddingTop="60px"
          formHeading={'RCOBAWA Sponsorship'}
          formInfo={'We only accept card payments at the moment'}
        >
          {error && <ErrorMsg error={error} />}
          <Formik
            initialValues={{ name: '', email: '', amount: undefined }}
            validationSchema={validationSchema()}
            onSubmit={(
              values: ISponsorshipForm,
              formikHelper: FormikHelpers<ISponsorshipForm>
            ) => {
              formSubmit(values, formikHelper)
              setTimeout(() => {
                formikHelper.setSubmitting(false)
              }, 500)
            }}
          >
            {(props: FormikProps<ISponsorshipForm>) => {
              const {
                values,
                handleBlur,
                handleChange,
                isSubmitting,
                submitForm,
              } = props
              return (
                <Form
                  css={css`
                    width: 505px;
                  `}
                  onSubmit={submitForm}
                >
                  <Input
                    type="text"
                    name="name"
                    label="Name"
                    placeholder="Enter your name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    fluid={true}
                    marginBottom="24px"
                  />
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="yourname@mail.com"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    fluid={true}
                    marginBottom="24px"
                  />
                  <CurrencyInput
                    name="amount"
                    label="Amount"
                    placeholder="Sponsorship Amount"
                    value={values.amount}
                    leftLabelText="AUD"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    fluid={true}
                    marginBottom="24px"
                  />
                  <div
                    css={css`
                      margin-top: 12px;
                      margin-bottom: 20px;
                    `}
                  >
                    <BraintreeDropIn setBraintree={setBraintree} />
                  </div>
                  <div
                    css={css`
                      margin-bottom: 20px;
                    `}
                  >
                    <Checkbox
                      label={
                        <label>
                          I agree with the &nbsp;
                          <Link href={'/termsAndConditions'} passHref>
                            <a
                              css={css`
                                cursor: pointer;
                              `}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms & Conditions
                            </a>
                          </Link>
                        </label>
                      }
                      id="terms"
                      checked={terms}
                      onChange={(e, { checked }) => setTerms(checked)}
                    />
                  </div>
                  <div
                    css={css`
                      margin-bottom: 20px;
                    `}
                  >
                    <Button
                      content={'Sponsor Now'}
                      id="sponsorBtn"
                      type="submit"
                      fluid={true}
                      loading={loading}
                      disabled={loading || isSubmitting || !terms}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </FormContainer>
      )}
      <Modal
        size={'mini'}
        open={modal}
        closeIcon={<Icon name="close" onClick={() => setModal(false)} />}
        css={css`
          width: auto;
          @media (min-width: 1024px) {
            width: 456px !important;
          }
        `}
      >
        <div
          css={css`
            padding: 24px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              .title {
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: ${theme.textPrimary.black};
              }
              .description {
                margin-top: 8px;
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${theme.textSecondary};
              }
              .btnContainer {
                margin-top: 30px;
                display: flex;
                justify-content: flex-end;
                .ui.green.button {
                  background: ${theme.success} !important;
                }
                .ui.basic.blue.button {
                  box-shadow: 0 0 0 1px ${theme.primary} inset !important;
                  color: ${theme.primary} !important;
                }
              }
            `}
          >
            <label className="title">
              Your RCOBAWA sponsorship value is ${amount.toFixed(2)}
            </label>
            <label className="description">
              {`Thank you for your commitment to sponsor RCOBAWA's upcoming event.
              Please click proceed to confirm.`}
            </label>
            <div className="btnContainer">
              <SemanticButton
                basic
                color="blue"
                onClick={() => setModal(false)}
              >
                Cancel
              </SemanticButton>
              <SemanticButton
                color="green"
                onClick={() => {
                  setValidated(true),
                    setModal(false),
                    document.getElementById('sponsorBtn').click()
                }}
              >
                Proceed
              </SemanticButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const SponsorshipForm = withTheme(SponsorshipComponent)
