import React, { FC } from 'react'
import { useRouter } from 'next/router'
import {
  CardContainer,
  DescriptionHead,
  Footer,
  GalleryCard,
  MagazineCard,
  NewsCard,
  SectionContainer,
} from '../components'
import { TabContainer } from '../components'
import { ISiteData } from '../../../@types/site'

export const MediaPage: FC<ISiteData> = ({ newsList, galleryList, magazineList }) => {

  const { asPath } = useRouter()
  let headerDescription =
    "We cover a wide selection of updates on Royal College activities in WA, the rest of Australia, and from Reid Avenue, Colombo. Everything that's newsworthy is presented here for you to be in the know."
  switch (asPath) {
    case '/media/gallery':
      headerDescription =
        'From laughter shared with fellow Old Boys to tense moments in cricket and rugby encounters with rivals, enjoy regular photo updates from all RCOBAWA events here.'
      break
    case '/media/magazines':
      headerDescription =
        'Published by the Royal College Old Boys in Australia Association, the FLOREAT magazine contains all things Royal College. Both latest and past issues can be conveniently downloaded here.'
      break
    default:
      break
  }
  return (
    <div>
      <DescriptionHead
        title="RCOBAWA Media"
        description={headerDescription}
        haveTabs
      />
      <TabContainer
        panes={[
          {
            menuItem: 'News',
            render: () => (
              <main>
                <SectionContainer
                  containerPaddingTop="80px"
                  containerPaddingBottom="56px"
                  page="Latest Updates"
                  heading="News and Articles"
                  content={
                    <CardContainer
                      containerMarginTop="64px"
                      columns={3}
                      dataSet={newsList}
                      Card={NewsCard}
                      spaceBottom="65px"
                      emptyMessage="No news and articles found. Please check later."
                    />
                  }
                />
              </main>
            ),
            path: '/media/news',
          },
          {
            menuItem: 'Gallery',
            render: () => (
              <main>
                <SectionContainer
                  containerPaddingTop="80px"
                  containerPaddingBottom="120px"
                  page="Gallery"
                  heading="Live the moment"
                  content={
                    <CardContainer
                      containerMarginTop="64px"
                      columns={3}
                      dataSet={galleryList}
                      Card={GalleryCard}
                      fullWidth
                      spaceBottom="24px"
                      emptyMessage="No galleries found. Please check later."
                    />
                  }
                />
              </main>
            ),
            path: '/media/gallery',
          },
          {
            menuItem: 'Magazines',
            render: () => (
              <main>
                <SectionContainer
                  containerPaddingTop="80px"
                  containerPaddingBottom="120px"
                  page="Magazines"
                  heading="Floreat Series"
                  content={
                    <CardContainer
                      containerMarginTop="64px"
                      columns={3}
                      dataSet={magazineList}
                      Card={MagazineCard}
                      spaceBottom="64px"
                      emptyMessage="No magazines found. Please check later."
                    />
                  }
                />
              </main>
            ),
            path: '/media/magazines',
          },
        ]}
      />
      <Footer />
    </div>
  )
}
