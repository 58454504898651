import React, { FC, ReactElement, ReactNode } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'

interface ISuccessContainerProps {
  theme: ITheme
  title?: string | ReactElement
  info?: string
  description?: string
  customContent?: ReactNode
  containerMarginTop?: string
}

const SuccessContainerComponent: FC<ISuccessContainerProps> = (props) => {
  const { theme, title, info, description, containerMarginTop, customContent } = props
  return (
    <div
      css={css`
        background-color: ${theme.white};
        margin-top: ${containerMarginTop};
        display: flex;
        flex-direction: column;
        max-width: 420px;
      `}
    >
      {title && (
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02px;
            color: ${theme.primary};
            word-wrap: break-word;
            text-align: left;
          `}
        >
          {title}
        </label>
      )}
      {info && (
        <label
          css={css`
            margin-top: 8px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: ${theme.textSecondary};
            word-wrap: break-word;
            text-align: left;
          `}
        >
          {info}
        </label>
      )}
      {description && (
        <label
          css={css`
            text-align: left;
            margin-top: 16px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            color: ${theme.textSecondary};
            word-wrap: break-word;
          `}
        >
          {description}
        </label>
      )}
      {customContent ? customContent : null}
    </div>
  )
}

export const SuccessContainer = withTheme(SuccessContainerComponent)
