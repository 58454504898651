import React, { FC, useState, useCallback, useEffect } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-awesome-lightbox'
import { ITheme } from '../../../../@types'
import { concat, map, size, slice } from 'lodash'
import PlaceholderImg from '../../assets/placeholder.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-awesome-lightbox/build/style.css'

interface IPhotosProps {
  src: string
  width: number
  height: number
}

interface IGalleryProps {
  theme: ITheme
  images: Array<IPhotosProps>
}

const GalleryComponent: FC<IGalleryProps> = (props) => {
  const LIMIT = 30
  const { theme, images } = props
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const [showMore, setShowMore] = useState(true)
  const [list, setList] = useState(slice(images, 0, LIMIT))
  const [index, setIndex] = useState(LIMIT)

  useEffect(() => {
    setShowMore(size(list) === size(images) ? false : true)
  }, [])

  useEffect(() => {
    setShowMore(size(list) === size(images) ? false : true)
  }, [list])

  useEffect(() => {
    setList(slice(images, 0, LIMIT))
    setIndex(LIMIT)
  }, [images])

  const loadMore = () => {
    const newIndex = index + LIMIT
    const newShowMore = newIndex < size(images) - 1
    const newList = concat(list, slice(images, index, newIndex))
    setIndex(newIndex)
    setList(newList)
    setShowMore(newShowMore)
  }

  const openLightbox = (index) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const imageRenderer = useCallback(({ index, photo, top, left }) => {
    return (
      <div
        css={css`
          margin: 2px;
          cursor: pointer;
          img {
            pointer-events: none;
          }
        `}
        onClick={() => openLightbox(index)}
        key={index}
      >
        <LazyLoadImage
          alt={index}
          src={photo.src}
          width={photo.width}
          height={photo.height}
          placeholderSrc={PlaceholderImg}
          style={{
            top: top,
            left: left,
            padding: 2,
            objectFit: 'cover',
          }}
        />
      </div>
    )
  }, [])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0px 100px;
        @media (max-width: 991px) {
          padding: 0px 16px !important;
        }
      `}
    >
      <div
        css={css`
          margin-top: 16px;
          margin-bottom: 40px;
          border: 1px solid #e8e7e7;
        `}
      />
      <Gallery photos={list} renderImage={imageRenderer} />
      {showMore && (
        <div
          css={css`
            margin-top: 49px;
            display: flex;
            justify-content: center;
          `}
          onClick={loadMore}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              @media (max-width: 991px) {
                align-items: center;
              }
            `}
          >
            <label
              css={css`
                margin-right: 10px;
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.25px;
                text-decoration-line: underline;
                color: ${theme.primary};
                cursor: pointer;
              `}
            >
              Load More
            </label>
          </div>
        </div>
      )}
      {viewerIsOpen && (
        <div
          css={css`
            img {
              pointer-events: none;
            }
          `}
        >
          <Lightbox
            images={map(list, (p) => ({
              url: p.src,
            }))}
            startIndex={currentImage}
            onClose={closeLightbox}
          />
        </div>
      )}
    </div>
  )
}

export const ImageGallery = withTheme(GalleryComponent)
