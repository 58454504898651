import React, { FC } from 'react'
import { filter, take } from 'lodash'
import {
  SinglePageHead,
  Footer,
  SectionContainer,
  News,
  CardContainer,
  NewsCard,
  JoinNowContainer,
} from '../components'
import { ISiteData } from '../../../@types/site'

export const NewsPage: FC<ISiteData> = ({ newsList, news }) => {
  const otherNews = filter(newsList, (n) => n.id !== news.id)
  return (
    <div>
      <SinglePageHead />
      <main>
        <SectionContainer
          containerPaddingTop="83px"
          containerPaddingBottom="100px"
          location="Media"
          heading={news.title}
          singlePage
          content={<News news={news} />}
        />
        <SectionContainer
          inverted
          containerPaddingTop="100px"
          containerPaddingBottom="115px"
          heading="Read More"
          content={
            <CardContainer
              containerMarginTop="64px"
              columns={3}
              dataSet={take(otherNews, 3)}
              Card={NewsCard}
              emptyMessage="No news and articles found. Please check later."
            />
          }
        />
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer />
    </div>
  )
}
