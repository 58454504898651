import React, { FC } from 'react'
import { css, withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'

interface IErrorMsgContainer {
  theme: ITheme
  error?: string
}

const ErrorMsgComponent: FC<IErrorMsgContainer> = ({ error, theme }) => {
  return (
    error && (
      <div
        css={css`
          padding: 12px 16px;
          background: #ffebee;
          border-radius: 4px;
          margin-bottom: 24px;
          width: 505px;
          @media (max-width: 991px) {
            width: 100%;
          }
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: ${theme.error};
          `}
        >
          {error}
        </label>
      </div>
    )
  )
}

export const ErrorMsg = withTheme(ErrorMsgComponent)
