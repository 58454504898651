import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import JoinArrowImg from '../assets/join-arrow.svg'
import { ITheme } from '../../../@types'
import { Grid, Image } from 'semantic-ui-react'

interface IJoinNowProps {
  title: string
  description: string
  containerMarginTop?: string
  theme: ITheme
}

const JoinNow: FC<IJoinNowProps> = (props) => {
  const { title, description, containerMarginTop = '0px', theme } = props
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: ${containerMarginTop};
        min-height: 446px;
        background-color: ${theme.secondary};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          @media (max-width: 1400px) {
            padding: 80px 100px !important;
          }
          @media (max-width: 1024px) {
            padding: 40px 50px !important;
          }
          @media (max-width: 991px) {
            padding: 40px 16px !important;
            flex-direction: column;
          }
        `}
      >
        <Grid
          css={css`
            margin: 0 !important;
          `}
        >
          <Grid.Column
            only="mobile tablet"
            mobile={16}
            tablet={16}
            css={css`
              padding: 0 !important;
            `}
          >
            <Image
              src={require('../assets/logo.png')}
              css={css`
                height: 130.22px;
                width: 94px;
                pointer-events: none;
              `}
            />
          </Grid.Column>
          <Grid.Column
            only="computer"
            computer={16}
            css={css`
              padding: 0 !important;
            `}
          >
            <Image
              src={require('../assets/logo-large.svg')}
              css={css`
                pointer-events: none;
              `}
            />
          </Grid.Column>
        </Grid>
        <div
          css={css`
            margin-left: 117.97px;
            display: flex;
            flex-direction: column;
            max-width: 630px;
            @media (max-width: 991px) {
              margin-left: 0px;
              margin-top: 24.3px;
            }
          `}
        >
          <label
            css={css`
              margin-top: 20px;
              font-family: ${theme.fontSecondary};
              font-style: normal;
              font-weight: 600;
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.02px;
              color: ${theme.black};
            `}
          >
            {title}
          </label>
          <label
            css={css`
              margin-top: 24px;
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;

              color: ${theme.black};
            `}
          >
            {description}
          </label>
          <div
            css={css`
              margin-top: 41px;
              display: flex;
              flex-direction: row;
              align-items: center;
              @media (max-width: 991px) {
                align-items: center;
              }
            `}
          >
            <Link href={'/membership'} passHref>
              <a>
                <label
                  css={css`
                    margin-right: 18px;
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                    text-decoration-line: underline;
                    color: ${theme.black};
                    cursor: pointer;
                  `}
                >
                  Join Now
                </label>
                <JoinArrowImg />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const JoinNowContainer = withTheme(JoinNow)
