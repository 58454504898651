import axios from 'axios'
import { FormikValues } from 'formik'
import { showError } from '../../../util/showError'
import { AppThunk } from '../../types'
import { InquiryAction } from './reducer'
import { InquiryTypes } from './types'

export const inquiryLoadingAction = (loading: boolean): InquiryAction => ({
  type: InquiryTypes.INQUIRY_LOADING,
  loading,
})

export const inquiryErrorAction = (error: string): InquiryAction => ({
  type: InquiryTypes.INQUIRY_ERROR,
  error,
})

export interface IInquiryForm extends FormikValues {
  name: string
  mobileNumber: string
  email: string
  inquiry: string
}

export const sentInquiry = (data: IInquiryForm): AppThunk => async (dispatch) => {
  try {
    dispatch(inquiryErrorAction(null))
    dispatch(inquiryLoadingAction(true))
    await axios.post('/inquiry', data);
  } catch (error) {
    const errMsg = showError(error);
    dispatch(inquiryErrorAction(errMsg))
    throw errMsg;
  } finally {
    dispatch(inquiryLoadingAction(false))
  }
}
