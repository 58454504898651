import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { css } from '@emotion/react'
import {
  SinglePageHead,
  Footer,
  SectionContainer,
  JoinNowContainer,
  Event,
} from '../components'
import { RootState } from '../../../redux/reducer'
import { getEventValidation, IValidEvent } from '../../../redux/actions'
import { Segment } from 'semantic-ui-react'
import { ISiteData } from '../../../@types/site'

export const EventPage: FC<ISiteData> = ({ event }) => {
  const dispatch = useDispatch()
  const eventValidLoading = useSelector(
    (state: RootState) => state.event.eventValidLoading
  )
  useEffect(() => {
    const user_id = process.browser && localStorage.getItem('user_id')
    const params: IValidEvent = {
      eventId: event.id,
    }
    if (user_id) {
      params.userId = user_id
    }
    dispatch(getEventValidation(params))
  }, [event.id])

  return (
    <div>
      <SinglePageHead />
      <main>
        <Segment
          basic
          css={css`
            margin: 0 !important;
            padding: 0 !important;
          `}
          loading={eventValidLoading}
        >
          <SectionContainer
            containerPaddingTop="83px"
            containerPaddingBottom="120px"
            location="Events"
            heading={event.title}
            content={<Event event={event} />}
            singlePage
          />
          <JoinNowContainer
            title="Join the Premier Alumni Association of our Alma Mater, Royal College."
            description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
          />
        </Segment>
      </main>
      <Footer />
    </div>
  )
}
