import axios from 'axios'
import { FormikValues } from 'formik'
import { showError } from '../../../util/showError'
import { AppThunk } from '../../types'
import { SponsorshipAction } from './reducer'
import { SponsorshipTypes } from './types'

export const sponsorshipLoadingAction = (loading: boolean): SponsorshipAction => ({
  type: SponsorshipTypes.SPONSORSHIP_LOADING,
  loading,
})

export const sponsorshipErrorAction = (error: string): SponsorshipAction => ({
  type: SponsorshipTypes.SPONSORSHIP_ERROR,
  error,
})

export interface ISponsorshipCreateForm extends FormikValues {
  name: string
  email: string
  amount: number
  token: string
}

export const createSponsorship = (data: ISponsorshipCreateForm): AppThunk => async (dispatch) => {
  try {
    dispatch(sponsorshipErrorAction(null))
    dispatch(sponsorshipLoadingAction(true))
    await axios.post('/sponsorship', data);
  } catch (error) {
    const errMsg = showError(error);
    dispatch(sponsorshipErrorAction(errMsg))
    throw errMsg;
  } finally {
    dispatch(sponsorshipLoadingAction(false))
  }
}
