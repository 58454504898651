import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form, Checkbox } from 'semantic-ui-react'
import { Input, Button } from '../../../components'
import { ITheme } from '../../../../../@types'
import { FormContainer } from '../FormContainer'
import { ErrorMsg } from '../ErrorMsgContainer'
import {
  IFormOne,
  IFormThree,
  IFormTwo,
  IRegisterForm,
  registerUser,
} from '../../../../../redux/actions'
import { RootState } from '../../../../../redux/reducer'

// form validationSchema
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .required('Please enter email address')
    .email('Please enter valid email address'),
})

interface IFilledForm {
  stepOne: IFormOne
  stepTwo: IFormTwo
  stepThree: IFormThree
}

interface IFormContainer {
  theme: ITheme
  setStep: (value: string) => void
  setMembershipType: (value: number) => void
  filled: IFilledForm
}

const SignUpComponent: FC<IFormContainer> = (props) => {
  const [terms, setTerms] = useState(false)
  const { setStep, filled, setMembershipType } = props

  useEffect(() => {
    document.getElementById('overlayModal').scrollIntoView({ behavior: 'smooth' })
  }, [])

  const loading = useSelector((state: RootState) => state.auth.registerLoading)
  const error = useSelector((state: RootState) => state.auth.registerError)
  const dispatch = useDispatch()

  const formSubmit = async (
    data: IFormThree,
    formikHelper: FormikHelpers<IFormThree>
  ) => {
    const registerForm: IRegisterForm = {
      ...filled.stepOne,
      ...filled.stepTwo,
      ...data,
      deceasedStatus: false,
      phoneNumber: `61${filled.stepOne.phoneNumber}`
    }
    await dispatch(registerUser(registerForm))
    formikHelper.resetForm({})
    setMembershipType(registerForm.membershipType)
    setStep('memberShipPayment')
  }

  return (
    <FormContainer
      containerPaddingTop="84px"
      formPage="3/3 Steps"
      formHeading="1 More step"
    >
      {error && <ErrorMsg error={error} />}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(
          values: IFormThree,
          formikHelper: FormikHelpers<IFormThree>
        ) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IFormThree>) => {
          const { values, handleBlur, handleChange, isSubmitting, submitForm } =
            props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="yourname@mail.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="40px"
              />
              <div
                css={css`
                  margin-bottom: 20px;
                `}
              >
                <Checkbox
                  label={
                    <label>
                      I agree with the &nbsp;
                      <Link href={'/termsAndConditions'} passHref>
                        <a
                          css={css`
                            cursor: pointer;
                          `}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </Link>
                    </label>
                  }
                  id="terms"
                  checked={terms}
                  onChange={(e, { checked }) => setTerms(checked)}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  @media (max-width: 991px) {
                    flex-direction: column;
                  }
                  button {
                    flex: 1;
                    margin-bottom: 24px !important;
                  }
                  button:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    button:first-child {
                      margin-right: 0px !important;
                    }
                  }
                  .ui.basic.button {
                    border: 1px solid #102770;
                    color: #102770 !important;
                  }
                `}
              >
                <Button
                  content="Back"
                  fluid={true}
                  onClick={() => setStep('stepTwo')}
                  basic
                />
                <Button
                  content="Submit"
                  type="submit"
                  fluid={true}
                  loading={loading}
                  disabled={loading || isSubmitting || !terms}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const StepThreeForm = withTheme(SignUpComponent)
