import axios from 'axios'
import Router from 'next/router'
import { FormikValues } from 'formik'
import jwtDecode from 'jwt-decode'
import * as FullStory from '@fullstory/browser'
import { showError } from '../../../util/showError'
import { AppThunk } from '../../types'
import {
  AuthAction,
  ForgotAction,
  ResetPasswordAction,
  RegisterAction,
} from './reducer'
import { AuthTypes } from './types'
import { get } from 'lodash'
import { WEB_APP_URL } from '../../../config'

export const loginCompletedAction = (token: string): AuthAction => ({
  type: AuthTypes.LOGIN_COMPLETED,
  token,
})

export const loginLoadingAction = (loading: boolean): AuthAction => ({
  type: AuthTypes.LOGIN_LOADING,
  loading,
})

export const loginErrorAction = (error: string): AuthAction => ({
  type: AuthTypes.LOGIN_ERROR,
  error,
})

export const renewMembershipAction = (membershipType: number): AuthAction => ({
  type: AuthTypes.RENEW_MEMBERSHIP,
  membershipType,
})

export interface ILogInForm extends FormikValues {
  username: string
  password: string
}

export interface IDecodeToken {
  id: number
  userType: number
}

export const loginUser =
  (data: ILogInForm): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(registerCompletedAction(null))
      dispatch(renewMembershipAction(null))
      dispatch(loginErrorAction(null))
      dispatch(loginLoadingAction(true))
      const response = await axios.post('/users/login', data)
      const decode: IDecodeToken = jwtDecode(response.data.token)
      await Promise.all([
        localStorage.setItem('user_id', String(decode.id)),
        localStorage.setItem('user_type', String(decode.userType)),
        localStorage.setItem('auth_token', response.data.token),
      ])
      FullStory.identify(String(decode.id))
      dispatch(loginLoadingAction(false))
      if (decode.userType === 1) {
        Router.replace(`${WEB_APP_URL}/loginSSO?token=${response.data.token}`)
      } else {
        Router.reload()
      }
    } catch (error) {
      const errMsg = showError(error)
      dispatch(loginErrorAction(errMsg))
      dispatch(loginLoadingAction(false))
      if (error.response && error.response.status === 402) {
        const userId = get(error, 'response.data.user.id', null)
        const membershipType = get(
          error,
          'response.data.user.membershipType',
          null
        )
        dispatch(registerCompletedAction(userId))
        dispatch(renewMembershipAction(membershipType))
      }
      throw errMsg
    }
  }

export const forgotLoadingAction = (loading: boolean): ForgotAction => ({
  type: AuthTypes.FORGOT_LOADING,
  loading,
})

export const forgotErrorAction = (error: string): ForgotAction => ({
  type: AuthTypes.FORGOT_ERROR,
  error,
})

export interface IForgotPasswordForm extends FormikValues {
  email: string
}

export const forgotPassword =
  (data: IForgotPasswordForm): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(forgotErrorAction(null))
      dispatch(forgotLoadingAction(true))
      await axios.post('/users/forgetPassword', data)
    } catch (error) {
      const errMsg = showError(error)
      dispatch(forgotErrorAction(errMsg))
      throw errMsg
    } finally {
      dispatch(forgotLoadingAction(false))
    }
  }

export const resetLoadingAction = (loading: boolean): ResetPasswordAction => ({
  type: AuthTypes.RESET_LOADING,
  loading,
})

export const resetErrorAction = (error: string): ResetPasswordAction => ({
  type: AuthTypes.RESET_ERROR,
  error,
})

export interface IResetPassword {
  password: string
  token: string
}

export const resetPassword =
  (data: IResetPassword, create: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resetErrorAction(null))
      dispatch(resetLoadingAction(true))
      await axios.post(create? '/users/setPassword' : '/users/resetPassword', data)
    } catch (error) {
      const errMsg = showError(error)
      dispatch(resetErrorAction(errMsg))
      throw errMsg
    } finally {
      dispatch(resetLoadingAction(false))
    }
  }

export const registerCompletedAction = (userId: number): RegisterAction => ({
  type: AuthTypes.REGISTER_COMPLETED,
  userId,
})

export const registerLoadingAction = (loading: boolean): RegisterAction => ({
  type: AuthTypes.REGISTER_LOADING,
  loading,
})

export const registerErrorAction = (error: string): RegisterAction => ({
  type: AuthTypes.REGISTER_ERROR,
  error,
})

export interface IFormOne extends FormikValues {
  firstName: string
  lastName: string
  spouse?: string
  phoneNumber: string
  DOB: string
  batch: string
  membershipType: number
}

export interface IFormTwo extends FormikValues {
  streetName: string
  suburb: string
  state: string
  postCode: number
}

export interface IFormThree extends FormikValues {
  email: string
}

export interface IRegisterForm extends FormikValues {
  firstName: string
  lastName: string
  DOB: string
  email: string
  phoneNumber: string
  batch: string
  streetName: string
  suburb: string
  state: string
  postCode: number
  deceasedStatus: boolean
  membershipType: number
  spouse?: string
}

export const registerUser =
  (data: IRegisterForm): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(registerCompletedAction(null))
      dispatch(registerErrorAction(null))
      dispatch(registerLoadingAction(true))
      const response = await axios.post('/users', data)
      dispatch(registerCompletedAction(response.data.id))
    } catch (error) {
      const errMsg = showError(error)
      dispatch(registerErrorAction(errMsg))
      throw errMsg
    } finally {
      dispatch(registerLoadingAction(false))
    }
  }
