import React, { FC } from 'react'
import {
  LandingHead,
  SectionContainer,
  CoverPoster,
  AboutSummary,
  JoinNowContainer,
  Footer,
  CardContainer,
  EventCard,
  NewsCard,
  NewsHeader,
  GalleryCard,
} from '../components'
import GroupPhotoWebp from '../assets/group-photo.webp'
import GroupPhotoJpg from '../assets/group-photo.jpg'
import { isEmpty } from 'lodash'
import { ISiteData } from '../../../@types/site'

export const HomePage: FC<ISiteData> = ({
  eventList,
  newsList,
  galleryList,
}) => {
  return (
    <div>
      <LandingHead
        title={"Royal College Old Boys'\n Association Western Australia"}
        description="RCOBAWA is an alumni organisation in Western Australia that is dedicated to Old Boys of the alma mater, Royal College Colombo. Incorporated in Perth, membership is open to all past students of Royal College (RC) Colombo, Sri Lanka and the Junior School formerly known as Royal Primary School (RPS)."
      />
      <main>
        {!isEmpty(eventList) && (
          <SectionContainer
            containerPaddingTop="80px"
            page="Events"
            heading="Upcoming Events"
            viewMoreLabel="View Events"
            viewMorePath="/events"
            content={
              <CardContainer
                containerMarginTop="64px"
                columns={3}
                dataSet={eventList}
                Card={EventCard}
                spaceBottom="24px"
                emptyMessage="No events scheduled for now. Please check later."
              />
            }
          />
        )}
        <SectionContainer
          containerPaddingTop={!isEmpty(eventList) ? '97px' : '80px'}
          page="Latest Updates"
          heading="News and Articles"
          viewMoreLabel="View More"
          viewMorePath="/media/news"
          content={
            <CardContainer
              Header={NewsHeader}
              containerMarginTop="64px"
              columns={3}
              dataSet={newsList}
              spaceBottom="24px"
              Card={NewsCard}
              emptyMessage="No news and articles found. Please check later."
            />
          }
        />
        <AboutSummary
          containerMarginTop="80px"
          organisation="RCOBAWA"
          heading="About Royal College Old Boys' Association Western Australia"
          content={`We welcome you to RCOBAWA's brand new website. In continuing our strong web presence together with our social media efforts, our web page will offer RCOBAWA members and Royalists across the globe a wide selection of news and information.
 
          The website also serves the purpose of a membership portal where RCOBAWA members can enjoy the many benefits of being a part of the sole alumni organisation of Royal College in Western Australia. `}
        />
        <CoverPoster webp={GroupPhotoWebp} jpg={GroupPhotoJpg} height="29vw" objectFit="cover"/>
        <SectionContainer
          containerPaddingTop="120px"
          containerPaddingBottom="80px"
          page="Gallery"
          heading="Live the moment"
          viewMoreLabel="View More"
          viewMorePath="/media/gallery"
          content={
            <CardContainer
              containerMarginTop="64px"
              columns={3}
              dataSet={galleryList}
              Card={GalleryCard}
              spaceBottom="24px"
              horizontalScroll
              emptyMessage="No galleries found. Please check later."
            />
          }
        />
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer />
    </div>
  )
}
