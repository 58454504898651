import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid } from 'semantic-ui-react'
import EmptyImg from '../assets/empty.svg'
import { ITheme } from '../../../@types'

interface IEmptyContainerProps {
  message: string
  theme: ITheme
}

const EmptyContainerComponent: FC<IEmptyContainerProps> = (props) => {
  const { message, theme } = props
  return (
    <Grid.Column
      width={16}
      css={css`
        padding: 50px !important;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <EmptyImg />
        <label
          css={css`
            max-width: 256px;
            margin-top: 13px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: ${theme.textSecondary};
          `}
        >
          {message}
        </label>
      </div>
    </Grid.Column>
  )
}

export const EmptyContainer = withTheme(EmptyContainerComponent)
