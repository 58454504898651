import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid, Image } from 'semantic-ui-react'
import { INews, ITheme } from '../../../../@types'
import { CardInfo } from './Sections/CardInfo'
import { CardContent } from './Sections/CardContent'
import { ViewMore } from './Sections/ViewMore'
import { getSlug } from '../../../../util/util'

interface INewsHeaderComponentProps {
  theme: ITheme
  data: INews
}

const NewsHeaderComponent: FC<INewsHeaderComponentProps> = (props) => {
  const { theme, data } = props
  return (
    <Grid.Column
      only="computer"
      width={16}
      css={css`
        padding: 0px 50px 24px 0px !important;
      `}
    >
      <div
        css={css`
          padding: 0px !important;
          display: flex;
          flex-direction: row;
          border-radius: 4px;
        `}
      >
        <div
          css={css`
            flex: 4;
            height: 448px;
          `}
        >
          <Image
            src={data.image}
            alt="news-header"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: fill;
              border-radius: 4px;
            `}
          />
        </div>
        <div
          css={css`
            flex: 3;
            background-color: ${theme.bgGray};
          `}
        >
          <div
            css={css`
              padding: 48px 24px;
              max-width: 471px;
            `}
          >
            <CardInfo published={data.createdAt} />
            <CardContent
              path={`/media/news/${data.id}/${getSlug(data.title)}`}
              title={data.title}
              description={data.content}
              htmlContent
              header
            />
            <ViewMore
              haveSeperator={false}
              path={`/media/news/${data.id}/${getSlug(data.title)}`}
              viewText="Read More"
            />
          </div>
        </div>
      </div>
    </Grid.Column>
  )
}

export const NewsHeader = withTheme(NewsHeaderComponent)
