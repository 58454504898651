import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { Image } from 'semantic-ui-react'

interface ISocialLinksProps {
  white?: boolean
}
export const SocialLinks: FC<ISocialLinksProps> = ({ white }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        #separate{
          width: 25px;
        }
      `}
    >
      <Link href="https://www.facebook.com/RCOBAWA/" passHref>
        <a target="_blank" rel="noopener noreferrer">
          <Image
            src={require(white
              ? '../../assets/facebook-ico-white.svg'
              : '../../assets/facebook-ico.svg')}
            css={css`
              width: 45px;
              height: 45px;
              cursor: pointer;
            `}
          />
        </a>
      </Link>
      <div id="separate"/>
      <Link href="https://www.instagram.com/rcobawa/" passHref>
        <a target="_blank" rel="noopener noreferrer">
          <Image
            src={require(white
              ? '../../assets/instagram-ico-white.svg'
              : '../../assets/instagram-ico.svg')}
            css={css`
              width: 45px;
              height: 45px;
              cursor: pointer;
            `}
          />
        </a>
      </Link>
    </div>
  )
}
