import React, { FC, useState } from 'react'
import Link from 'next/link'
import Router from 'next/router'
import { useDispatch } from 'react-redux'
import { css } from '@emotion/react'
import { Image, Menu as SemanticMenu, MenuItemProps } from 'semantic-ui-react'
import { map } from 'lodash'
import { MAIN_ROUTES } from '../../../../constant'
import { INFO_EMAIL, WEB_APP_URL } from '../../../../config'
import { getMainRoute } from '../../../../util/util'
import { OverlayModal } from '../Overlay'
import { InquiryForm } from '../Forms'
import { FormChanger } from '../Forms/FormChanger'
import { logoutUser } from '../../../../redux/actions'

interface INavLabelProps extends MenuItemProps {
  title: string
  path: string
}
const NavigationLabel: FC<INavLabelProps> = ({
  title,
  path,
  navigation,
  theme,
}) => {
  const mainRoute = getMainRoute(path)
  return (
    <div>
      <Link href={path} passHref>
        <a
          css={css`
            margin-top: 24px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: ${theme.textPrimary.blue} !important;
            text-decoration: ${navigation === mainRoute ? 'underline' : 'none'};
            font-weight: ${navigation === mainRoute ? 'bold' : 'normal'};
            text-decoration-thickness: 2px;
            &:hover {
              color: ${theme.textPrimary.blue} !important;
            }
            -webkit-tap-highlight-color: transparent;
          `}
        >
          {title}
        </a>
      </Link>
    </div>
  )
}

export const MobileNavigation: FC<MenuItemProps> = (props) => {
  const [modal, setNavModal] = useState(false)
  const { isInverted = false, theme } = props
  const dispatch = useDispatch()
  const userType = process.browser && localStorage.getItem('user_type')
  const AuthRoute = () => {
    switch (userType) {
      case '1':
        return (
          <AuthLabel
            label="Admin"
            onClick={() => Router.replace(`${WEB_APP_URL}/admin/manageMembers`)}
          />
        )
      case '2':
        return (
          <AuthLabel label="Logout" onClick={() => dispatch(logoutUser())} />
        )
      default:
        return null
    }
  }

  const AuthLabel = ({ label, ...rest }) => (
    <label
      css={css`
        margin-top: 24px;
        font-family: ${theme.fontPrimary};
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: ${theme.textPrimary.blue} !important;
        text-decoration: none;
        font-weight: normal;
        text-decoration-thickness: 2px;
        -webkit-tap-highlight-color: transparent;
      `}
      {...rest}
    >
      {label}
    </label>
  )

  return (
    <SemanticMenu.Menu position="right">
      <SemanticMenu.Item
        onClick={() => setNavModal(true)}
        css={css`
          padding: 0 !important;
        `}
      >
        <Image
          src={require(isInverted
            ? '../../assets/mobile-menu.svg'
            : '../../assets/mobile-menu-inverted.svg')}
          css={css`
            width: 24px;
            height: 24px;
            pointer-events: none;
          `}
        />
      </SemanticMenu.Item>
      <div
        css={css`
          height: 100%;
          width: ${modal ? '100%' : '0'};
          position: fixed;
          z-index: 1;
          top: 0;
          right: 0;
          background-color: ${theme.white};
          overflow-x: hidden;
          transition: 0.5s;
        `}
      >
        <div
          css={css`
            margin-top: 48px;
            padding: 40px;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              cursor: pointer;
              align-self: flex-end;
            `}
            onClick={() => setNavModal(false)}
          >
            <Image
              src={require('../../assets/close-btn.svg')}
              css={css`
                width: 15.99px;
                height: 15.99px;
                pointer-events: none;
              `}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: 90px;
              text-align: center;
              div {
                margin-top: 24px;
              }
            `}
          >
            {map(MAIN_ROUTES, (route, i) => (
              <NavigationLabel
                key={i}
                {...route}
                {...props}
              />
            ))}
            <OverlayModal
              containerProps={{ onClick: () => setNavModal(false) }}
              trigger={
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 28px;
                    color: ${theme.textPrimary.blue} !important;
                    text-decoration: none;
                    font-weight: normal;
                    text-decoration-thickness: 2px;
                    -webkit-tap-highlight-color: transparent;
                  `}
                >
                  Contact Us
                </label>
              }
              heading="Royal College Old Boys' Association Western Australia"
              emailTo={INFO_EMAIL}
              socialLinks
              location="4 Kersley Loop, Harrisdale WA 6112"
            >
              <InquiryForm />
            </OverlayModal>
            {userType ? (
              <div>
                <AuthRoute />
              </div>
            ) : (
              <OverlayModal
                containerProps={{ onClick: () => setNavModal(false) }}
                trigger={<AuthLabel label="Login" />}
                heading="Royal College Old Boys' Association Western Australia"
                description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
              >
                <FormChanger initialForm="signIn" />
              </OverlayModal>
            )}
          </div>
        </div>
      </div>
    </SemanticMenu.Menu>
  )
}
