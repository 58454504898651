import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Menu } from '../index'
import { ITheme } from '../../../../@types'
import { Button, Image } from 'semantic-ui-react'
import { OverlayModal } from '../Overlay'
import { SignUpSteps } from '../Forms'

interface Props {
  theme: ITheme
  title: string
  description: string
}
const LandingHeadComponent: FC<Props> = ({ theme, title, description }) => {
  return (
    <div
      css={css`
        height: 50em;
        @media (max-width: 760px) {
          height: 60em;
        }
        picture {
          pointer-events: none;
        }
      `}
    >
      <picture>
        <source
          srcSet={require('../../assets/landing-poster.webp')}
          type="image/webp"
        />
        <source
          srcSet={require('../../assets/landing-poster.jpg')}
          type="image/jpeg"
        />
        <Image
          src={require('../../assets/landing-poster.webp')}
          css={css`
            width: 100%;
            height: 100%;
            object-fit: fill;
            @media (max-width: 991px) {
              object-fit: cover;
            }
          `}
        />
      </picture>
      <Menu isInverted />
      <div
        css={css`
          z-index: 1;
          position: absolute;
          top: 128px;
          padding: 0px 100px;
          margin-top: 128px;
          display: flex;
          flex-direction: column;
          max-width: 850px;
          @media (max-width: 991px) {
            padding: 0px 16px 0px 16px !important;
          }
          @media (max-width: 760px) {
            top: 88px;
            margin-top: 88px;
          }
        `}
      >
        <h1
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.02px;
            color: ${theme.white};
            white-space: pre-line;
            margin: 0;
          `}
        >
          {title}
        </h1>
        <label
          css={css`
            margin-top: 16px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: ${theme.white};
          `}
        >
          {description}
        </label>
        <div
          css={css`
            margin-top: 40px;
          `}
        >
          <OverlayModal
            trigger={
              <Button
                css={css`
                  border-radius: 4px !important;
                  background-color: ${theme.white} !important;
                  font-family: ${theme.fontSecondary} !important;
                  font-style: normal !important;
                  font-weight: normal !important;
                  font-size: 16px !important;
                  line-height: 18px !important;

                  text-align: center !important;
                  letter-spacing: 0.5px !important;
                  color: ${theme.primary} !important;

                  &:hover {
                    border: 1px solid ${theme.primary};
                    box-sizing: border-box;
                  }
                  @media (max-width: 767px) {
                    margin-bottom: 94px !important;
                  }
                `}
              >
                Become a member
              </Button>
            }
            heading="Royal College Old Boys' Association Western Australia"
            description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
          >
            <SignUpSteps initialForm="stepOne" />
          </OverlayModal>
        </div>
      </div>
    </div>
  )
}

export const LandingHead = withTheme(LandingHeadComponent)
