import React, { FC, useState } from 'react'
import { StepOneForm } from './StepOne'
import { StepTwoForm } from './StepTwo'
import { StepThreeForm } from './StepThree'
import { MembershipPaymentForm } from './MembershipPayment'
interface IStepContainer {
  initialForm: string
  membership?: number | undefined
  renew?: boolean | undefined
}

export const SignUpSteps: FC<IStepContainer> = (props) => {
  const initialValues = {
    stepOne: null,
    stepTwo: null,
    stepThree: null,
  };
  const { initialForm, membership, renew=false } = props
  const [step, setStep] = useState(initialForm)
  const [form, setForm] = useState(initialValues)
  const [membershipType, setMembershipType] = useState(membership)

  const setFormValues = (values) => {
    setForm({
      ...form,
      ...values,
    })
  }

  switch (step) {
    case 'stepOne':
      return (
        <StepOneForm
          setStep={(type) => setStep(type)}
          filled={form.stepOne}
          setFormValues={(values) => setFormValues(values)}
          membership={membership}
        />
      )
    case 'stepTwo':
      return (
        <StepTwoForm
          setStep={(type) => setStep(type)}
          filled={form.stepTwo}
          setFormValues={(values) => setFormValues(values)}
        />
      )
    case 'stepThree':
      return (
        <StepThreeForm
          setStep={(type) => setStep(type)}
          setMembershipType={(type) => setMembershipType(type)}
          filled={form}
        />
      )
    case 'memberShipPayment':
      return <MembershipPaymentForm membershipType={membershipType} renew={renew} />
    default:
      return null
  }
}
