import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Image } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { INews, ITheme } from '../../../../@types'
import moment from 'moment'

interface INewsProps {
  theme: ITheme
  news: INews
}

const NewsComponent: FC<INewsProps> = (props) => {
  const { theme, news } = props
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0px 100px;
        @media (max-width: 991px) {
          padding: 0px 16px !important;
        }
      `}
    >
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: ${theme.textSecondary};
          `}
        >
          Published on {moment(news.createdAt).format('DD-MM-YYYY')}
        </label>
        <div
          css={css`
            margin-top: 16px;
            margin-bottom: 40px;
            border: 1px solid #e8e7e7;
          `}
        />
        <div
          css={css`
            width: 70%;
            @media (max-width: 991px) {
              width: 100%;
            }
          `}
        >
          <Image
            src={news.image}
            alt="news-image"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: fill;
            `}
          />
        </div>
        <div
          css={css`
            max-width: 850px;
            margin-top: 64px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: ${theme.textPrimary};
            word-wrap: break-word;
            iframe{
              @media (max-width: 991px) {
                width: 100%;
              }
            }
          `}
          dangerouslySetInnerHTML={{
            __html: news.content,
          }}
        />
      </div>
    </div>
  )
}

export const News = withTheme(NewsComponent)
