import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Button, Image } from 'semantic-ui-react'
import { AddToCalender } from './AddToCalender'
import { IEvent, ITheme } from '../../../../@types'
import LocationPinImg from '../../assets/location-dark.svg'
import TicketImg from '../../assets/ticket-dark.svg'
import AlertImg from '../../assets/alert.svg'
import AlertCircleImg from '../../assets/alert-circle.svg'
import { OverlayModal } from '../Overlay'
import { TicketPurchaseForm } from '../Forms'
import { get } from 'lodash'
import { RootState } from '../../../../redux/reducer'
import { FormChanger } from '../Forms/FormChanger'
import moment from 'moment'

interface IBuyTicketProps {
  theme: ITheme
  event: IEvent
}

const BuyTicketComponent: FC<IBuyTicketProps> = (props) => {
  const { theme, event } = props
  const user_id = process.browser && localStorage.getItem('user_id')
  const eventValidLoading = useSelector(
    (state: RootState) => state.event.eventValidLoading
  )
  const eventValid = useSelector((state: RootState) => state.event.eventValid)
  const isBeforeDate = moment().isSameOrBefore(event.date)
  const remainTickets =
    event.numberOfTickets - get(event, 'ItemPayments[0].ticketSold', 0)
  const eventDisabled =
    event.numberOfTickets <= get(event, 'ItemPayments[0].ticketSold', 0)
  const freeEvent = Boolean(event.ticketPrice === 0)
  const accessValidation = get(eventValid, 'accessValidation', true)
  const group = get(eventValid, 'group', '')
  const disableBtn = !accessValidation || eventDisabled || eventValidLoading
  return (
    <div
      css={css`
        padding: 0px !important;
        display: flex;
        flex-direction: row;
        @media (max-width: 991px) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          flex: 7;
          min-height: 448px;
          @media (max-width: 991px) {
            min-height: 208px;
          }
        `}
      >
        <Image
          src={event.eventImage}
          alt="event-image"
          css={css`
            width: 100%;
            height: 100%;
            object-fit: fill;
          `}
        />
      </div>
      <div
        css={css`
          flex: 3;
          background-color: ${theme.bgGray};
        `}
      >
        <div
          css={css`
            padding: 20px 40px;
            max-width: 471px;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            @media (max-width: 991px) {
              padding: 20px;
            }
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontSecondary};
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              letter-spacing: -0.02px;
              color: ${theme.primary};
            `}
          >
            Event details
          </label>
          <AddToCalender event={event} />
          {event.venueName && (
            <span
              css={css`
                display: flex;
                align-items: center;
                margin-top: 25px;
              `}
            >
              <LocationPinImg />
              <div
                css={css`
                  margin-left: 12px;
                `}
              >
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.fontPrimary};
                  `}
                >
                  {event.venueName}
                </label>
              </div>
            </span>
          )}
          {Boolean(remainTickets) && event.numberOfTickets && (
            <span
              css={css`
                display: flex;
                align-items: center;
                margin-top: 24px;
              `}
            >
              <TicketImg />
              <div
                css={css`
                  margin-left: 10px;
                `}
              >
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.fontPrimary};
                  `}
                >
                  {event.numberOfTickets -
                    get(event, 'ItemPayments[0].ticketSold', 0)}{' '}
                  Remaining
                </label>
              </div>
            </span>
          )}
          <span
            css={css`
              margin-top: 52px;
            `}
          >
            <label
              css={css`
                font-family: ${theme.fontSecondary};
                font-style: normal;
                font-weight: 800;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -0.02px;
                color: ${theme.primary};
              `}
            >
              {freeEvent ? 'Free' : `$${event.ticketPrice}`}
            </label>
            <label
              css={css`
                font-family: ${theme.fontSecondary};
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                letter-spacing: -0.02px;
                color: ${theme.primary};
              `}
            >
              /ticket
            </label>
          </span>
          {isBeforeDate && (
            <div>
              <div
                css={css`
                  margin-top: 16px;
                `}
              >
                <OverlayModal
                  trigger={
                    <Button
                      css={css`
                        height: 60px;
                        font-family: ${theme.fontPrimary} !important;
                        font-style: normal !important;
                        font-weight: normal !important;
                        font-size: 20px !important;
                        line-height: 28px !important;
                        text-align: center !important;
                        color: ${disableBtn
                          ? theme.black
                          : theme.white} !important;
                        background-color: ${disableBtn
                          ? theme.textSecondary
                          : theme.primary} !important;
                        border-radius: 4px !important;
                        &:hover {
                          background: ${theme.primaryDark} !important;
                          border: 1px solid ${theme.primary} !important;
                          box-sizing: border-box !important;
                        }
                      `}
                      fluid={true}
                      disabled={disableBtn}
                    >
                      {eventDisabled
                        ? 'Tickets Sold Out'
                        : freeEvent
                        ? 'Get Pass'
                        : 'Buy Tickets'}
                    </Button>
                  }
                  heading={event.title}
                  date={event.date}
                  time={event.time}
                  location={event.venueName}
                >
                  <TicketPurchaseForm event={event} freeEvent={freeEvent} />
                </OverlayModal>
              </div>
              {event.accessType !== 'public' && (
                <div
                  css={css`
                    margin-top: 16px;
                  `}
                >
                  <span
                    css={css`
                      display: flex;
                      align-items: center;
                      font-family: ${theme.fontPrimary};
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 22px;
                      letter-spacing: -0.25px;
                      color: ${theme.error};

                      svg {
                        margin-right: 8px;
                      }
                    `}
                  >
                    {user_id ? (
                      group ? (
                        <>
                          <AlertImg />
                          This event is only accessible for {group}
                        </>
                      ) : null
                    ) : (
                      <>
                        <AlertCircleImg /> Please
                        <OverlayModal
                          trigger={
                            <label
                              css={css`
                                font-weight: 700;
                                text-decoration: underline;
                                margin: 0px 4px;
                                cursor: pointer;
                              `}
                            >
                              login
                            </label>
                          }
                          heading="Royal College Old Boys' Association Western Australia"
                          description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
                        >
                          <FormChanger initialForm="signIn" />
                        </OverlayModal>
                        to continue
                      </>
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const BuyTicket = withTheme(BuyTicketComponent)
