import { AxiosError } from "axios"

export function showError(error: AxiosError): string {
  if (error.response) {
    if (error.response.data.message) {
      return error.response.data.message
    } else if (error.response.statusText || error.response.request.statusText) {
      return error.response.statusText || error.response.request.statusText
    } else {
      return 'Something went wrong...'
    }
  } else {
    return 'Something went wrong...'
  }
}
