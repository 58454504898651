import React, { FC } from 'react'
import Link from 'next/link'
import Router from 'next/router'
import { useDispatch } from 'react-redux'
import { css } from '@emotion/react'
import { Button, Menu as SemanticMenu, MenuItemProps } from 'semantic-ui-react'
import { map, omit } from 'lodash'
import { MAIN_ROUTES } from '../../../../constant'
import { INFO_EMAIL, WEB_APP_URL } from '../../../../config'
import { getMainRoute } from '../../../../util/util'
import { OverlayModal } from '../Overlay'
import { InquiryForm } from '../Forms'
import { FormChanger } from '../Forms/FormChanger'
import { logoutUser } from '../../../../redux/actions'

const Item: FC<MenuItemProps> = (props) => {
  const { theme, isInverted = false, navigation, route } = props
  const mainRoute = getMainRoute(route.path)
  return (
    <SemanticMenu.Item
      {...omit(props, ['theme', 'text', 'navigation', 'isInverted', 'route'])}
      css={css`
        background: transparent !important;
      `}
    >
      <Link href={route.path} passHref>
        <a
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            color: ${isInverted
              ? theme.white
              : theme.textPrimary.blue} !important;
            text-decoration: ${navigation === mainRoute ? 'underline' : 'none'};
            font-weight: ${navigation === mainRoute ? 'bold' : 'normal'};
            text-decoration-thickness: 2px;
            cursor: pointer;
            &:hover {
              color: ${isInverted
                ? theme.white
                : theme.textPrimary.blue} !important;
              text-decoration: underline;
              text-decoration-thickness: 2px;
            }
          `}
        >
          {route.title}
        </a>
      </Link>
    </SemanticMenu.Item>
  )
}

export const WebNavigation: FC<MenuItemProps> = (props) => {
  const dispatch = useDispatch()
  const userType = process.browser && localStorage.getItem('user_type')
  const AuthRoute = () => {
    switch (userType) {
      case '1':
        return (
          <AuthBtn
            label="Admin"
            onClick={() =>
              Router.replace(
                `${WEB_APP_URL}/admin/manageMembers`
              )
            }
          />
        )
      case '2':
        return (
          <AuthBtn
            label="Logout"
            onClick={() => dispatch(logoutUser())}
          />
        )
      default:
        return null
    }
  }

  const AuthBtn = ({ label, ...rest }) => (
    <Button
      inverted={isInverted}
      css={css`
        width: 84px !important;
        height: 32px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 4px !important;
        background: ${isInverted ? 'transparent' : theme.primary} !important;
        color: ${theme.white} !important;
        cursor: pointer;
        &:hover {
          background: ${isInverted
            ? theme.white
            : theme.primaryDark} !important;
          border: 1px solid ${theme.primary} !important;
          box-sizing: border-box !important;
          color: ${isInverted ? theme.primary : theme.white} !important;
        }
      `}
      {...rest}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary} !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 14px !important;
          line-height: 22px !important;
          letter-spacing: -0.25px !important;
        `}
      >
        {label}
      </label>
    </Button>
  )

  const { theme, isInverted = false } = props
  return (
    <SemanticMenu.Menu position="right">
      {map(MAIN_ROUTES, (route, i) => {
        return <Item key={i} route={route} {...props} />
      })}
      <SemanticMenu.Item>
        <OverlayModal
          trigger={
            <label
              css={css`
              cursor: pointer;
              &:hover {
                text-decoration: underline;
                text-decoration-thickness: 2px;
              }
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${
                isInverted ? theme.white : theme.textPrimary.blue
              } !important;
              text-decoration: 'none'};
              font-weight: 'normal'};
              text-decoration-thickness: 2px;
            `}
            >
              Contact Us
            </label>
          }
          heading="Royal College Old Boys' Association Western Australia"
          emailTo={INFO_EMAIL}
          socialLinks
          location="4 Kersley Loop, Harrisdale WA 6112"
        >
          <InquiryForm />
        </OverlayModal>
      </SemanticMenu.Item>
      <SemanticMenu.Item>
        {userType ? (
          <AuthRoute />
        ) : (
          <OverlayModal
            trigger={<AuthBtn label="Login" />}
            heading="Royal College Old Boys' Association Western Australia"
            description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
          >
            <FormChanger initialForm="signIn" />
          </OverlayModal>
        )}
      </SemanticMenu.Item>
    </SemanticMenu.Menu>
  )
}
