import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'

interface IInfoContainerProps {
  message: string
  theme: ITheme
  containerMarginBottom?: string
}

const InfoContainerComponent: FC<IInfoContainerProps> = (props) => {
  const { message, theme, containerMarginBottom } = props
  return (
    <div
      css={css`
        margin-bottom: ${containerMarginBottom};
        display: flex;
        flex-direction: column;
        padding: 0px 108px 0px 100px;
        @media (max-width: 991px) {
            padding: 0px 16px !important;
        }
      `}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary};
          font-style: italic;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;
          color: ${theme.textSecondary};
          max-width: 925px;
        `}
      >
        {message}
      </label>
    </div>
  )
}

export const InfoContainer = withTheme(InfoContainerComponent)
