import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Image } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../../@types'
import { isNumber } from 'lodash'

interface ICardImageProps {
  theme: ITheme
  image: string
  price?: number | undefined
}

const CardImageComponent: FC<ICardImageProps> = (props) => {
  const { theme, image, price = undefined } = props
  const freeEvent = Boolean(price === 0)
  return (
    <div
      css={css`
        border-radius: 4px;
        height: 208px;
      `}
    >
      <Image
        src={image}
        alt="news-header"
        css={css`
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 4px;
        `}
      />
      {isNumber(price) && (
        <div
          css={css`
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            padding: 4px 16px;
            margin-top: 8px;
            margin-right: 58px;
            background: ${theme.bgGray};
            border-radius: 4px;
            @media (max-width: 767px) {
              margin-top: 20px;
              margin-right: 20px;
            }
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.25px;
              color: ${theme.primary};
            `}
          >
            {freeEvent ? 'Free' : `AUD $${price}`}
          </label>
        </div>
      )}
    </div>
  )
}

export const CardImage = withTheme(CardImageComponent)
