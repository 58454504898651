import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../../@types'
import CalenderImg from '../../../assets/calender.svg'
import LocationPinImg from '../../../assets/location.svg'
import moment from 'moment'

interface ICardInfoProps {
  theme: ITheme
  date?: string
  time?: string
  location?: string
  published?: string
}

const CardInfoComponent: FC<ICardInfoProps> = (props) => {
  const { theme, date, time, location, published } = props
  return (
    <div
      css={css`
        margin-top: 9px;
        display: flex;
        flex-direction: row;
        label {
          font-family: ${theme.fontPrimary};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.25px;
          color: ${theme.textSecondary};
        }
        span {
          flex: 1;
          display: flex;
          label {
            margin-left: 12px;
          }
        }
      `}
    >
      {date && (
        <span>
          <CalenderImg />
          <label>{moment(date).format('DD-MM-YYYY')} {time && moment(time, "HH:mm:ss").format('h:mmA')}</label>
        </span>
      )}
      {location && (
        <span>
          <LocationPinImg />
          <label>{location}</label>
        </span>
      )}
      {published && <label>Published on {moment(published).format('DD-MM-YYYY')}</label>}
    </div>
  )
}

export const CardInfo = withTheme(CardInfoComponent)
