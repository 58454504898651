import React, { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'
import { Grid } from 'semantic-ui-react'
import { FooterWeb } from './FooterWeb'
import { FooterMobile } from './FooterMobile'
import { split } from 'lodash'


interface IFooterProps {
  theme: ITheme
  isNav?: boolean
}

const FooterComponent: FC<IFooterProps> = (props) => {
  const router = useRouter()
  const [navigation, setnavigation] = useState('/')
  const { theme } = props

  useEffect(() => {
    const route = `/${split(router.route, '/')[1]}`;
    setnavigation(route)
  }, [])

  return (
    <footer
      css={css`
        background-color: ${theme.bgGray};
      `}
    >
      <Grid
        css={css`
          margin: 0 !important;
          min-height: 142px;
        `}
      >
       <FooterWeb navigation={navigation} {...props} />
       <FooterMobile navigation={navigation} {...props} />
      </Grid>
    </footer>
  )
}

export const Footer = withTheme(FooterComponent)
