import { NextPage } from 'next'
import { css } from '@emotion/react'
import { Image } from 'semantic-ui-react'
import { MetaHead } from './MetaHead'
import { ISiteExport } from '../@types'

export const UnderConstruction: NextPage<ISiteExport> = ({ meta }) => {
  return (
    <div
      css={css`
        background-color: #102770;
        height: 100vh;
        @media (max-width: 991px) {
          padding: 0px 16px;
        }
      `}
    >
      <MetaHead
        meta={{
          ...meta,
          title:
            'Royal College Old Boys’ Association Western Australia | RCOBAWA',
          description:
            'RCOBAWA is an alumni organisation in the state of WA, Australia that is dedicated to Old Boys of the beloved Alma Mater, Royal College. Incorporated in Perth - WA, membership is open to all past students of Royal College (RC) Colombo, Sri Lanka and the Junior School formerly known as Royal Primary School (RPS).',
        }}
      />
      <div
        css={css`
          /* Full-screen */
          height: 100%;
          /* Add a white text color to all elements inside the .bgimg container */
          color: white;
          /* Add a font */
          font-family: 'Courier New', Courier, monospace;
          /* Set the font-size to 25 pixels */
          font-size: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
        `}
      >
        <div>
          <Image
            css={css`
              margin-bottom: 40px;
              width: 100px;
              pointer-events: none;
            `}
            src={require(`../assets/logo.png`)}
            alt="logo"
            size="small"
          />
        </div>
        <div>
          <h1>SITE UNDER CONSTRUCTION</h1>
        </div>
      </div>
    </div>
  )
}
