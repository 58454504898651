import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Form, InputProps } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { ITheme } from '../../../@types'
import { get } from 'lodash'

interface JLocationSearchProps extends InputProps {
  theme: ITheme
  marginBottom: string
  setFieldValue: (field: string, value: string | number) => void
}

const LocationSearchComponent: FC<JLocationSearchProps> = (props) => {
  const { theme, marginBottom, setFieldValue } = props

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyCV0xMTsyqGXhZdIpeUTaRZlzJO_XX5vRQ',
    options: {
      types: ["address"],
      componentRestrictions: { country: "au" },
    },
    onPlaceSelected: async (place) => {
      if (place.address_components && place.address_components.length) {
        try {
          const [
            streetNumber,
            streetAddress,
            state,
            suburb,
            suburbExtra,
            postalCodes,
          ] = await Promise.all([
            place.address_components
              .filter((it) => {
                return it.types.indexOf('street_number') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
            place.address_components
              .filter((it) => {
                return it.types.indexOf('route') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
            place.address_components
              .filter((it) => {
                return it.types.indexOf('administrative_area_level_1') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
            place.address_components
              .filter((it) => {
                return it.types.indexOf('locality') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
            place.address_components
              .filter((it) => {
                return it.types.indexOf('political') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
            place.address_components
              .filter((it) => {
                return it.types.indexOf('postal_code') !== -1
              })
              .map((it) => {
                return it.long_name
              }),
          ])

          // set location search place
          if (streetNumber[0]) {
            place.streetNumber = streetNumber[0]
          }
          if (streetAddress[0]) {
            place.streetAddress = streetAddress[0]
          }
          if (state[0]) {
            place.state = state[0]
          }
          if (suburb[0] || suburbExtra[0]) {
            place.suburb = suburb[0] || suburbExtra[0]
          }
          if (postalCodes[0]) {
            place.postcode = postalCodes[0]
          }
          const getStreet =
            get(place, 'streetNumber', null) ||
            get(place, 'streetAddress', null)
          if (getStreet) {
            setFieldValue('streetName', getStreet)
          }
          const getSuburb = get(place, 'suburb', null)
          if (getSuburb) {
            setFieldValue('suburb', getSuburb)
          }
          const getState = get(place, 'state', null)
          if (getState) {
            setFieldValue('state', getState)
          }
          const getPostalCode = get(place, 'postcode', null)
          if (getPostalCode) {
            setFieldValue('postCode', Number(getPostalCode))
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
  })

  return (
    <Form.Field
      css={css`
        margin-bottom: ${marginBottom} !important;
        label {
          display: flex !important;
        }
      `}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary} !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 24px !important;
          color: ${theme.black} !important;
        `}
      >
        {props.label}
      </label>

      <div className="ui fluid icon input">
        <input
          ref={ref}
          type="text"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          css={css`
            height: 48px !important;
            border: 1px solid ${theme.stroke} !important;
            box-sizing: border-box !important;
            border-radius: 4px !important;
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 24px !important;
            background-color: ${theme.white} !important;
            ::placeholder {
              color: ${theme.textSecondary} !important;
              opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: ${theme.textSecondary} !important;
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: ${theme.textSecondary} !important;
            }
          `}
        />
        <i aria-hidden="true" className="search icon"></i>
      </div>
    </Form.Field>
  )
}

export const LocationSearch = withTheme(LocationSearchComponent)
