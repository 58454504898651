import React, { FC, useState } from 'react'
import Router, { useRouter } from 'next/router'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers, FormikValues } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { PasswordInput, Button } from '../../components'
import { IResetPassword, resetPassword } from '../../../../redux/actions'
import { RootState } from '../../../../redux/reducer'
import { ITheme } from '../../../../@types'
import { FormContainer } from './FormContainer'
import { ErrorMsg } from './ErrorMsgContainer'
import { SuccessContainer } from './SuccessContainer'

// form validationSchema
const validationSchema = (create: boolean) => yup.object().shape({
  password: yup
    .string()
    .label(create? 'Password' : 'New Password')
    .min(8, 'Must be at least 8 characters')
    .required('Please enter valid password'),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .min(8, 'Must be at least 8 characters')
    .required('Please enter valid password')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

interface IFormContainer {
  theme: ITheme
  create?: boolean
}

interface IResetPasswordForm extends FormikValues {
  password: string
  confirmPassword: string
}

const ResetPasswordComponent: FC<IFormContainer> = (props) => {
  const [success, setSuccess] = useState(false)
  const {
    query: { token },
  } = useRouter()
  const { theme, create=false } = props
  const loading = useSelector((state: RootState) => state.auth.resetLoading)
  const error = useSelector((state: RootState) => state.auth.resetError)
  const dispatch = useDispatch()

  const formSubmit = async (
    data: IResetPasswordForm,
    formikHelper: FormikHelpers<IResetPasswordForm>
  ) => {
    const params: IResetPassword = {
      password: data.password,
      token: String(token)
    }
    await dispatch(resetPassword(params, create))
    formikHelper.resetForm({})
    setSuccess(true)
  }

  return success ? (
    <SuccessContainer
      containerMarginTop="84px"
      title={create? "🙌🏻 Password Set Successfully!" : "🙌🏻 Password Changed"}
      description={create? "You've successfully created a password for your member account. You can now sign in with your password." : "Your password was reset successfully. You can now sign in with your new password."}
      customContent={
        <div
          css={css`
            margin-top: 52px;
          `}
        >
          <Button onClick={() => Router.push('/')} content="Go Back to Homepage" fluid />
        </div>
      }
    />
  ) : (
    <FormContainer
      containerPaddingTop="84px"
      formHeading={create? "Create Password" : "Create New Password"}
      formInfo={create? "Let's create a unique password for your member account!" : "Let's create a new password for your account"}
    >
      {error && <ErrorMsg error={error} />}
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema(create)}
        onSubmit={(
          values: IResetPasswordForm,
          formikHelper: FormikHelpers<IResetPasswordForm>
        ) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IResetPasswordForm>) => {
          const { values, handleBlur, handleChange, isSubmitting, submitForm } =
            props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <PasswordInput
                name="password"
                label={create? "Password" : "New Password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <PasswordInput
                name="confirmPassword"
                label="Confirm Password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="40px"
              />
              <Button
                content={create? "Set Password" : "Reset Password"}
                type="submit"
                fluid={true}
                loading={loading}
                disabled={loading || isSubmitting}
              />
              <div
                css={css`
                  margin-top: 16px;
                  text-align: left;
                `}
              >
                <Link href="/" passHref>
                  <a
                    css={css`
                      font-family: ${theme.fontPrimary};
                      font-style: normal;
                      font-weight: normal;
                      font-size: 16px;
                      line-height: 24px;
                      color: ${theme.primary};
                      text-decoration-line: underline;
                      cursor: pointer;
                      &:hover{
                        color: ${theme.primary} !important;
                        text-decoration-line: underline !important;
                      }
                    `}
                  >
                    Go back to Home
                  </a>
                </Link>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const ResetPasswordForm = withTheme(ResetPasswordComponent)
