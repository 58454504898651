import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers, FormikValues } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { Button, Input, BraintreeDropIn } from '../../../components'
import { ITheme } from '../../../../../@types'
import { FormContainer } from '../FormContainer'
import { ErrorMsg } from '../ErrorMsgContainer'
import LockImg from '../../../assets/lock.svg'
import { SuccessContainer } from '../SuccessContainer'
import { RootState } from '../../../../../redux/reducer'
import {
  IMembershipOnboard,
  membershipPurchase,
} from '../../../../../redux/actions'
import Router from 'next/router'

// form validationSchema
const validationSchema = yup.object().shape({
  membership: yup.string().label('Selected Membership'),
})

interface IFormContainer {
  theme: ITheme
  expiredUser: any
}

export interface IForm extends FormikValues {
  membership: string
}

const MembershipRenewComponent: FC<IFormContainer> = (props) => {
  const [success, setSuccess] = useState(false)
  const [braintree, setBraintree] = useState(null)
  const { theme, expiredUser } = props

  const loading = useSelector(
    (state: RootState) => state.membership.membershipPurchaseLoading
  )
  const error = useSelector(
    (state: RootState) => state.membership.membershipPurchaseError
  )

  const dispatch = useDispatch()

  const formSubmit = async (
    data: IForm,
    formikHelper: FormikHelpers<IForm>
  ) => {
    // Send the nonce to your server
    const { nonce } = await braintree.requestPaymentMethod()
    // API call integration will be here. Handle success / error response accordingly.
    const form: IMembershipOnboard = {
      paymentMethodNonce: nonce,
      membershipTypeId: expiredUser.membershipsType.id,
      amount: expiredUser.membershipsType.amount,
      paymentMode: 'CARD',
      paymentType: 'MEMBERSHIP',
      userId: String(expiredUser.id),
    }
    await dispatch(membershipPurchase(form))
    formikHelper.resetForm({})
    setSuccess(true)
  }

  useEffect(() => {
    if (error) {
      braintree.clearSelectedPaymentMethod()
    }
  }, [error])

  return (
    <>
      <div
        css={css`
          display: ${success ? '' : 'none'};
        `}
      >
        <SuccessContainer
          containerMarginTop="84px"
          title="🙌🏻 Submitted Successfully"
          description="Your form has been successfully submitted. Please check your email for receipt."
          customContent={
            <div
              css={css`
                margin-top: 52px;
              `}
            >
              <Button onClick={() => Router.push('/')} content="Go Back to Homepage" fluid />
            </div>
          }
        />
      </div>
      <div
        css={css`
          display: ${success ? 'none' : ''};
        `}
      >
        <FormContainer
          containerPaddingTop="0px"
          formPage='Renew Membership'
          formHeading="Secured Payment"
          formInfo="We only accept card payments at the moment"
          formHeadingIcon={LockImg}
        >
          {error && <ErrorMsg error={error} />}
          <Formik
            initialValues={{ membership: '' }}
            validationSchema={validationSchema}
            onSubmit={(values: IForm, formikHelper: FormikHelpers<IForm>) => {
              formSubmit(values, formikHelper)
              setTimeout(() => {
                formikHelper.setSubmitting(false)
              }, 500)
            }}
          >
            {(props: FormikProps<IForm>) => {
              const { isSubmitting, submitForm } = props
              return (
                <Form
                  css={css`
                    width: 505px;
                  `}
                  onSubmit={submitForm}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      @media (max-width: 991px) {
                        flex-direction: column;
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        margin-right: 16px;
                        @media (max-width: 991px) {
                          margin-right: 0;
                        }
                      `}
                    >
                      <Input
                        type="text"
                        name="membership"
                        label="Selected Membership"
                        value={expiredUser.membershipsType.title}
                        readOnly
                      />
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        @media (max-width: 991px) {
                          margin-top: 24px;
                        }
                      `}
                    >
                      <label
                        css={css`
                          font-family: ${theme.fontPrimary};
                          font-style: normal;
                          font-weight: normal;
                          font-size: 16px;
                          line-height: 24px;
                          color: ${theme.black};
                        `}
                      >
                        Total Price
                      </label>
                      <label
                        css={css`
                          margin-top: 8px;
                          font-family: ${theme.fontSecondary};
                          font-style: normal;
                          font-weight: 600;
                          font-size: 32px;
                          line-height: 40px;
                          letter-spacing: -0.02px;
                          color: ${theme.black};
                        `}
                      >
                        ${expiredUser.membershipsType.amount}
                      </label>
                    </div>
                  </div>
                  <div
                    css={css`
                      margin-top: 40px;
                      margin-bottom: 40px;
                    `}
                  >
                    <BraintreeDropIn setBraintree={setBraintree} />
                  </div>
                  <div
                    css={css`
                      margin-bottom: 20px;
                    `}
                  >
                    <label>
                      By clicking Purchase, you agree to our &nbsp;
                      <Link href={'/termsAndConditions'} passHref>
                        <a
                          css={css`
                            cursor: pointer;
                          `}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </Link>
                    </label>
                  </div>
                  <Button
                    content="Purchase"
                    type="submit"
                    fluid={true}
                    loading={loading}
                    disabled={loading || isSubmitting}
                  />
                </Form>
              )
            }}
          </Formik>
        </FormContainer>
      </div>
    </>
  )
}

export const MembershipRenewForm = withTheme(MembershipRenewComponent)
