import React, { FC } from 'react'
import { css, withTheme } from '@emotion/react'
import Link from 'next/link'
import { Image } from 'semantic-ui-react'
import { ITheme } from '../../../../@types'
import { Button } from '../Button'

interface IErrorProps {
  theme: ITheme
}

const NotFoundComponent: FC<IErrorProps> = (props) => {
  const { theme } = props
  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0px 100px;
        @media (max-width: 991px) {
          margin: 0px 16px;
        }
      `}
    >
      <div
        css={css`
          flex:1;
          text-align: center;
          display: flex;
          align-items: center;
        `}
      >
        <Link href={'/'} passHref>
          <a
            css={css`
              cursor: pointer;
            `}
          >
            <Image
              src={require('../../assets/logo.png')}
              css={css`
                width: 43.01px;
                height: 60px;
                pointer-events: none;
              `}
            />
          </a>
        </Link>
      </div>
      <div
        css={css`
          flex:9;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 144px;
            line-height: 144px;
            text-align: center;
            letter-spacing: 0.1px;
            color: ${theme.black};
          `}
        >
          404
        </label>
        <label
          css={css`
            margin-top: 16px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: ${theme.textSecondary};
          `}
        >
          Page not found
        </label>
        <label
          css={css`
            margin-top: 24px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.25px;
            color: ${theme.black};
            max-width: 520px;
          `}
        >
          We are sorry, the page you requested cannot be found.The URL may be
          misspelled or the page you&apos;re looking for is no longer available.
        </label>
        <div
          css={css`
            margin-top: 24px;
            width: 450px;
            @media (max-width: 991px) {
              width: auto;
            }
          `}
        >
          <Link href={'/'} passHref>
            <Button content="Go Back to home" fluid={true} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export const NotFound = withTheme(NotFoundComponent)
