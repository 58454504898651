import React, { FC } from 'react'
import {
  Footer,
  JoinNowContainer,
  SponsorshipForm,
  SponsorshipContainer,
} from '../components'

export const SponsorshipPage: FC = () => {
  return (
    <div>
      <main>
        <SponsorshipContainer>
          <SponsorshipForm />
        </SponsorshipContainer>
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer isNav={false} />
    </div>
  )
}
