// regex
export const REG_EXP_PHONE =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const REG_EXP_NUMBER_ONLY = /^[0-9]*$/

// Navigation routes
export const MAIN_ROUTES = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Membership',
    path: '/membership',
  },
  {
    title: 'Events',
    path: '/events',
  },
  {
    title: 'Media',
    path: '/media/news',
  },
  {
    title: 'About',
    path: '/about',
  },
]

export const FOOTER_ROUTES = [
  ...MAIN_ROUTES,
  {
    title: 'Terms & Conditions',
    path: '/termsAndConditions',
  },
]