import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { IEvent, ITheme } from '../../../../@types'
import { BuyTicket } from './BuyTicket'
interface IEventProps {
  theme: ITheme
  event: IEvent
}

const EventComponent: FC<IEventProps> = (props) => {
  const { theme, event } = props
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0px 100px;
        @media (max-width: 991px) {
          padding: 0px 16px !important;
        }
      `}
    >
      <div>
        <div
          css={css`
            margin-top: 16px;
            margin-bottom: 40px;
            border: 1px solid #e8e7e7;
          `}
        />
        <BuyTicket event={event} />
      </div>
      <div
        css={css`
          margin-top: 64px;
          display: flex;
          flex-direction: column;
          max-width: 817px;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02px;
            color: ${theme.primary};
            word-wrap: break-word;
          `}
        >
          About This Event
        </label>
        <label
          css={css`
            margin-top: 40px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            color: ${theme.black};
            word-wrap: break-word;
          `}
        >
          {event.description}
        </label>
      </div>
    </div>
  )
}

export const Event = withTheme(EventComponent)
