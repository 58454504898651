import React, { FC, useEffect } from 'react'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { Input, Button, LocationSearch } from '../../../components'
import { ITheme } from '../../../../../@types'
import { FormContainer } from '../FormContainer'
import { isEmpty } from 'lodash'
import { IFormTwo } from '../../../../../redux/actions'

// form validationSchema
const validationSchema = yup.object().shape({
  streetName: yup
    .string()
    .label('Street Number')
    .required('Please enter street number'),
  suburb: yup.string().label('Suburb').required('Please enter suburb'),
  state: yup.string().label('State').required('Please enter state'),
  postCode: yup
    .string()
    .label('Postal Code')
    .required('Please enter postal code'),
})

interface IStepTwo {
  stepTwo: IFormTwo
}

interface IFormContainer {
  theme: ITheme
  setStep: (value: string) => void
  setFormValues: (value: IStepTwo) => void
  filled: IFormTwo | null
}

const SignUpComponent: FC<IFormContainer> = (props) => {
  const { theme, setStep, setFormValues, filled } = props

  useEffect(() => {
    document.getElementById('overlayModal').scrollIntoView({ behavior: 'smooth' })
  }, [])

  const formSubmit = async (
    data: IFormTwo,
    formikHelper: FormikHelpers<IFormTwo>
  ) => {
    setFormValues({ stepTwo: data })
    formikHelper.resetForm({})
    setStep('stepThree')
  }

  return (
    <FormContainer
      containerPaddingTop="84px"
      formPage="2/3 Steps"
      formHeading="Almost there!"
    >
      <Formik
        initialValues={
          !isEmpty(filled)
            ? filled
            : {
                streetName: '',
                suburb: '',
                state: '',
                postCode: '',
              }
        }
        validationSchema={validationSchema}
        onSubmit={(values: IFormTwo, formikHelper: FormikHelpers<IFormTwo>) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IFormTwo>) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            submitForm,
          } = props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <LocationSearch
                label="Search Address"
                marginBottom="24px"
                setFieldValue={setFieldValue}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  div {
                    flex: 1;
                  }
                  div:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    flex-direction: column;
                    div:first-child {
                      margin-right: 0px !important;
                    }
                  }
                `}
              >
                <Input
                  type="text"
                  name="streetName"
                  label="Street Name / Number"
                  placeholder="Your street number"
                  value={values.streetName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="24px"
                />
                <Input
                  type="text"
                  name="suburb"
                  label="Suburb"
                  placeholder="Your suburb"
                  value={values.suburb}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="24px"
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  div {
                    flex: 1;
                  }
                  div:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    flex-direction: column;
                    div:first-child {
                      margin-right: 0px !important;
                    }
                  }
                `}
              >
                <Input
                  type="text"
                  name="state"
                  label="State"
                  placeholder="Select your state"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="40px"
                />
                <Input
                  type="number"
                  name="postCode"
                  label="Postal Code"
                  placeholder="Your postal code"
                  value={values.postCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="40px"
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  @media (max-width: 991px) {
                    flex-direction: column;
                  }
                  button {
                    flex: 1;
                    margin-bottom: 24px !important;
                  }
                  button:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    button:first-child {
                      margin-right: 0px !important;
                    }
                  }
                  .ui.basic.button {
                    border: 1px solid ${theme.primary};
                    color: ${theme.primary} !important;
                  }
                `}
              >
                <Button
                  content="Back"
                  fluid={true}
                  onClick={() => setStep('stepOne')}
                  basic
                />
                <Button
                  content="Next"
                  type="submit"
                  fluid={true}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const StepTwoForm = withTheme(SignUpComponent)
