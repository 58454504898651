import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { IEvent, ITheme } from '../../../../@types'
import moment from 'moment'
import CalenderImg from '../../assets/calender-dark.svg'
import JoinArrowImg from '../../assets/join-arrow-primary.svg'

interface IAddToCalenderProps {
  theme: ITheme
  event: IEvent
}

const AddToCalenderComponent: FC<IAddToCalenderProps> = (props) => {
  const { theme, event } = props
  const date = moment(event.date).format('YYYY-MM-DD')
  const time = moment(event.time, 'HH:mm:ss').format('h:mmA')

  // tell moment how to parse the input string
  const eventDate = moment(date + time, 'YYYY-MM-DDLT')
  const utcDate = moment(eventDate).utc()

  // conversion
  const dateTime = utcDate.format('YYYYMMDDTHHmmss')

  const saveCalInvite = () => {
    // Create the .ics URL
    const url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTART:' + `${dateTime}Z`,
      'DTEND:' + `${dateTime}Z`,
      'SUMMARY:' + event.title,
      'DESCRIPTION:' + event.description,
      'LOCATION:' + event.venueName,
      'BEGIN:VALARM',
      'TRIGGER:-PT15M',
      'REPEAT:1',
      'DURATION:PT15M',
      'ACTION:DISPLAY',
      'DESCRIPTION:Reminder',
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')

    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      // Open/Save link in IE and Edge
      window.navigator.msSaveBlob(blob, `add-to-calender-${event.title}.ics`)
    } else {
      // Open/Save link in Modern Browsers
      window.open(encodeURI('data:text/calendar;charset=utf8,' + url))
    }
  }

  return (
    <>
      {event.date && (
        <span
          css={css`
            display: flex;
            align-items: flex-start;
            margin-top: 24px;
          `}
        >
          <CalenderImg />
          <div
            css={css`
              margin-left: 11px;
            `}
          >
            <label
              css={css`
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: ${theme.fontPrimary};
              `}
            >
              {moment(date).format('ddd, MMM DD, YYYY')}{' '}
              {time}
            </label>
            <div
              css={css`
                margin-top: 9px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                @media (max-width: 991px) {
                  align-items: center;
                }
              `}
              onClick={saveCalInvite}
            >
              <label
                css={css`
                  margin-right: 10px;
                  font-family: ${theme.fontPrimary};
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.25px;
                  text-decoration-line: underline;
                  color: ${theme.primary};
                  cursor: pointer;
                `}
              >
                Add to calendar
              </label>
              <JoinArrowImg />
            </div>
          </div>
        </span>
      )}
    </>
  )
}

export const AddToCalender = withTheme(AddToCalenderComponent)
