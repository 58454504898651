import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Image } from 'semantic-ui-react'

interface ICoverPosterProps {
  webp: string
  jpg: string
  containerMarginTop?: string
  height: string
  objectFit: string
}

const CoverPosterComponent: FC<ICoverPosterProps> = (props) => {
  const { webp, jpg, height = '29vw', containerMarginTop = '0px', objectFit="cover" } = props
  return (
    <div
      css={css`
        picture{
          pointer-events: none;
        }
      `}
    >
      <picture>
        <source srcSet={webp} type="image/webp" />
        <source srcSet={jpg} type="image/jpeg" />
        <Image
          src={webp}
          css={css`
            margin-top: ${containerMarginTop};
            object-fit: ${objectFit};
            width: 100%;
            height: ${height};
            @media (max-width: 767px) {
              min-height: 36vw;
            }
          `}
        />
      </picture>
    </div>
  )
}

export const CoverPoster = withTheme(CoverPosterComponent)
