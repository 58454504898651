import { ITheme } from "../../@types";

export const theme: ITheme = {
  fontPrimary: 'Inter',
  fontSecondary: 'Manrope',
  
  black: '#000000',
  white: '#FFFFFF',

  primary: '#102770',
  primaryDark: '#081746',
  secondary: '#FFC83D',

  success: '#2E7D32',
  error: '#C62828',

  textPrimary: {
    blue: '#102770',
    black: '#000000',
  },
  textSecondary: '#6C6C6C',

  bgWhite: '#FFFFFF',
  bgGray: '#F9F9F9',

  stroke: '#BABABA',
};
