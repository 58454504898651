import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid, Image } from 'semantic-ui-react'
import { IMagazine, ITheme } from '../../../../@types'
import JoinArrowImg from '../../assets/join-arrow.svg'
import { limitString } from '../../../../util/util'

interface IMagazineCardComponentProps {
  theme: ITheme
  spaceBottom?: string
  only: string
  data: IMagazine
}

const MagazineCardComponent: FC<IMagazineCardComponentProps> = (props) => {
  const { theme, spaceBottom = '0px', data, only } = props
  return (
    <Grid.Column
      css={css`
        padding: 0px 50px ${spaceBottom} 0px !important;
        @media (max-width: 767px) {
          flex: 0 0 90% !important;
        }
      `}
      only={only}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 295px;
          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 40px;
          }
        `}
      >
        <div
          css={css`
            height: 384px;
          `}
        >
          <Image
            src={data.thumbnail}
            alt="magazine-thumbnail"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: fill;
            `}
          />
        </div>
        <label
          css={css`
            margin-top: 16px;
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.02px;
            color: ${theme.black};
            overflow: hidden;
            text-overflow: ellipsis;
            height: 5ex;
          `}
        >
          {limitString(data.title, 50)}
        </label>
        <Link href={data.file} passHref>
          <a
            css={css`
              margin-top: 16px;
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              @media (max-width: 991px) {
                align-items: center;
              }
            `}
            target="_blank"
            rel="noopener noreferrer"
          >
            <label
              css={css`
                margin-right: 10px;
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.25px;
                text-decoration-line: underline;
                color: ${theme.black};
                cursor: pointer;
              `}
            >
              Read Magazine
            </label>
            <JoinArrowImg />
          </a>
        </Link>
      </div>
    </Grid.Column>
  )
}

export const MagazineCard = withTheme(MagazineCardComponent)
