import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import moment from 'moment'
import { find, isEmpty, map } from 'lodash'
import { Input, PhoneInput, Button, Dropdown } from '../../../components'
import { IMembership, ITheme } from '../../../../../@types'
import { FormContainer } from '../FormContainer'
import { DatePicker } from '../../DatePicker'
import { REG_EXP_PHONE } from '../../../../../constant'
import { IFormOne } from '../../../../../redux/actions'
import { RootState } from '../../../../../redux/reducer'
// form validationSchema
const validationSchema = (memberships: Array<IMembership>) =>
  yup.object().shape({
    firstName: yup
      .string()
      .label('First Name')
      .required('Please enter first name'),
    lastName: yup
      .string()
      .label('Last Name')
      .required('Please enter last name'),
    spouse: yup.string().label('Spouse/Partner Name (optional)'),
    phoneNumber: yup
      .string()
      .matches(REG_EXP_PHONE, 'Phone number is not valid')
      .min(8, 'to short')
      .label('Phone Number')
      .required('Please enter phone number'),
    DOB: yup
      .string()
      .label('Date of Birth')
      .required('Please enter date of birth'),
    batch: yup
      .string()
      .label('Royal Batch')
      .required('Please select royal batch'),
    membershipType: yup
      .string()
      .label('Membership Type')
      .test(
        'DOB',
        'Only people under the age of 25 can use this membership type',
        (value, data) => {
          const studentMember = find(
            memberships,
            (i) => i.id === Number(value) && i.title === 'Student Member'
          )
          if (studentMember) {
            const dob = data.parent['DOB']
            if (dob) {
              const date = moment(dob, 'DD/MM/YYYY')
              const convertedDate = date.format('YYYY-MM-DD')
              const userAge = moment().diff(convertedDate, 'years')
              if (userAge > 25) {
                return false
              } else {
                return true
              }
            }
          }
          return true
        }
      )
      .required('Please select membership type'),
  })

interface IStepOne {
  stepOne: IFormOne
}

interface IFormContainer {
  theme: ITheme
  setStep: (value: string) => void
  setFormValues: (value: IStepOne) => void
  filled: IFormOne | null
  membership?: number | undefined
}

const SignUpComponent: FC<IFormContainer> = (props) => {
  const { setStep, setFormValues, filled, membership } = props
  const memberships = useSelector(
    (state: RootState) => state.membership.memberships
  )

  useEffect(() => {
    document
      .getElementById('overlayModal')
      .scrollIntoView({ behavior: 'smooth' })
  }, [])

  const formSubmit = async (
    data: IFormOne,
    formikHelper: FormikHelpers<IFormOne>
  ) => {
    // convert date into submittable format
    const date = moment(data.DOB, 'DD/MM/YYYY')
    const convertedDate = date.format('YYYY-MM-DD')
    data.DOB = convertedDate

    setFormValues({ stepOne: data })
    formikHelper.resetForm({})
    setStep('stepTwo')
  }
  
  const minBatch = (DOB) => {
    const currentYear = moment().year()
    if (!DOB) return currentYear - 50
    const date = moment(DOB, 'DD/MM/YYYY')
    const convertedDate = date.format('YYYY-MM-DD')
    return moment(convertedDate).year()
  }

  const renderMembershipTypes = () => {
    return map(memberships, (item, i) => ({
      key: i,
      text: item.title,
      value: item.id,
    }))
  }

  return (
    <FormContainer
      containerPaddingTop="84px"
      formHeading="Sign Up"
      formInfo="Please fill up the membership form below."
    >
      <Formik
        initialValues={
          !isEmpty(filled)
            ? {
                ...filled,
                DOB: moment(filled.DOB).format('DD/MM/YYYY'),
              }
            : {
                firstName: '',
                lastName: '',
                spouse: '',
                phoneNumber: '',
                DOB: '',
                batch: '',
                membershipType: membership ? membership : '',
              }
        }
        validationSchema={validationSchema(memberships)}
        onSubmit={(values: IFormOne, formikHelper: FormikHelpers<IFormOne>) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IFormOne>) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            submitForm,
          } = props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  div {
                    flex: 1;
                  }
                  div:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    flex-direction: column;
                    div:first-child {
                      margin-right: 0px !important;
                    }
                  }
                `}
              >
                <Input
                  type="text"
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="24px"
                />
                <Input
                  type="text"
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="24px"
                />
              </div>
              <Input
                type="text"
                name="spouse"
                label="Spouse/Partner Name (optional)"
                placeholder="Enter your spouse/partner name"
                value={values.spouse}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  div {
                    flex: 1;
                  }
                  div:first-child {
                    margin-right: 16px !important;
                  }
                  @media (max-width: 991px) {
                    flex-direction: column;
                    div:first-child {
                      margin-right: 0px !important;
                    }
                  }
                `}
              >
                <PhoneInput
                  type="text"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Number"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fluid={true}
                  marginBottom="24px"
                />
                <DatePicker
                  name="DOB"
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  label="Date of Birth"
                  value={values.DOB}
                  iconPosition="right"
                  startMode="year"
                  onChange={(e, { value }) => setFieldValue('DOB', value)}
                  initialDate={
                    new Date(moment().subtract(14, 'y').format('YYYY'))
                  }
                  maxDate={new Date(moment().subtract(14, 'y').format('YYYY'))}
                  marginBottom="24px"
                  fluid={true}
                  inputType="date"
                  autoComplete="off"
                />
              </div>
              <DatePicker
                name="batch"
                dateFormat="YYYY"
                placeholder="What is your royal batch?"
                label="Royal Batch"
                value={values.batch}
                iconPosition="right"
                startMode="year"
                onChange={(e, { value }) => setFieldValue('batch', value)}
                initialDate={new Date(moment().subtract(1, 'y').format('YYYY'))}
                minDate={minBatch(values.DOB)}
                maxDate={new Date(moment().subtract(1, 'y').format('YYYY'))}
                marginBottom="24px"
                fluid={true}
                inputType="year"
                autoComplete="off"
              />
              <Dropdown
                selectOnBlur={false}
                options={renderMembershipTypes()}
                type="select"
                name="membershipType"
                label="Membership Type"
                placeholder="Select you membership"
                value={values.membershipType}
                onChange={(e, { value }) =>
                  setFieldValue('membershipType', value)
                }
                selection
                width="100%"
                search
                marginBottom="40px"
              />
              <Button
                content="Next"
                type="submit"
                fluid={true}
                disabled={isSubmitting}
              />
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const StepOneForm = withTheme(SignUpComponent)
