import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid, Image } from 'semantic-ui-react'
import { IPastPresident, ITheme } from '../../../../@types'

interface IPastPresidentCardComponentProps {
  theme: ITheme
  spaceBottom?: string
  data: IPastPresident
  fullWidth?: boolean
}

const PastPresidentCardComponent: FC<IPastPresidentCardComponentProps> = (
  props
) => {
  const { theme, spaceBottom = '0px', data } = props
  return (
    <Grid.Column
      css={css`
        padding: 0px 0px ${spaceBottom} 0px !important;
      `}
    >
      <div
         css={css`
         display: flex;
         flex-direction: column;
         max-width: 210px;
         @media (max-width: 991px) {
           max-width: 131px;
         }
         picture{
           pointer-events: none;
         }
       `}
      >
        <picture>
          <source
            srcSet={
              data.webp
                ? data.webp
                : require('../../assets/committee-avatar.svg')
            }
            type="image/webp"
          />
          <source
            srcSet={
              data.jpg ? data.jpg : require('../../assets/committee-avatar.svg')
            }
            type="image/jpeg"
          />
          <Image
            rounded
            src={
              data.webp
                ? data.webp
                : require('../../assets/committee-avatar.svg')
            }
          />
        </picture>        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 24px;
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              color: ${theme.black};
            `}
          >
            {data.name}
          </label>
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${theme.textSecondary};
            `}
          >
            {data.year}
          </label>
        </div>
      </div>
    </Grid.Column>
  )
}

export const PastPresidentCard = withTheme(PastPresidentCardComponent)
