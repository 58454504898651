import React, { FC } from 'react'
import {
  AboutHead,
  Footer,
  TermsContent
} from '../components'

export const TermsAndConditionsPage: FC = () => {
  return (
    <div>
      <AboutHead
        title="Terms & Conditions"
        description='PERMISSION TO USE WWW.RCOBAWA.ORG.AU ("WEB SITE") IS CONDITIONAL UPON YOU AGREEING TO THE TERMS OF USE SET OUT BELOW ("TERMS OF USE"). YOUR USE OF THE WEB SITE IS DEEMED TO BE YOUR ACCEPTANCE OF THESE TERMS OF USE. IF YOU DO NOT ACCEPT THESE TERMS OF USE, YOU MUST REFRAIN FROM USING THE WEB SITE.'
      />
      <main>
        <TermsContent />
      </main>
      <Footer />
    </div>
  )
}
