import { split, take } from 'lodash'
import slugify from 'slugify'

export function getMainRoute(path: string): string {
  return `/${split(path, '/')[1]}`
}

export function limitString(content: string, length: number): string {
  const contentString = content.replace(/(<([^>]+)>)/gi, '')
  if (contentString.length > length) {
    return `${contentString.slice(0, length)}...`
  }
  return contentString
}

export function getSlug(content: string): string {
  const contentString = content.split(' ')
  return slugify(take(contentString, 6).join(' '), {
    lower: true, // convert to lower case, defaults to `false`,
    strict: true, // strip special characters except replacement, defaults to `false`
  })
}
