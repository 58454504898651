import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Button as SemanticButton, ButtonProps } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'
import { omit } from 'lodash'

interface IButtonProps extends ButtonProps{
  theme: ITheme
}

const ButtonComponent: FC<IButtonProps> = (props) => {
  const { theme } = props
  return (
    <SemanticButton
      css={css`
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background-color: ${theme.primary} !important;
        border-radius: 4px !important;
        height: 48px !important;
        font-family: ${theme.fontPrimary} !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        letter-spacing: -0.017px !important;
        color: ${theme.white} !important;
        &:hover {
          background: ${theme.primaryDark} !important;
        }
      `}
      {...omit(props, ['theme'])}
    />
  )
}

export const Button = withTheme(ButtonComponent)
