import React, { FC, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { css } from '@emotion/react'
import { Image, Menu as SemanticMenu, MenuProps, Grid } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { MobileNavigation } from './MobileNavigation'
import { WebNavigation } from './WebNavigation'
import { getMainRoute } from '../../../../util/util'

const MenuComponent: FC<MenuProps> = (props) => {
  const router = useRouter()
  const { isInverted = false, theme } = props
  const [navigation, setnavigation] = useState('/')
  const [colorChange, setColorchange] = useState(false)

  useEffect(() => {
    const mainRoute = getMainRoute(router.route)
    setnavigation(mainRoute)

    // Handler to call on scroll resize
    const changeNavbarColor = () => {
      if (window.scrollY >= 10) {
        setColorchange(true)
      } else {
        setColorchange(false)
      }
    }
    // Add event listener
    window.addEventListener('scroll', changeNavbarColor)
    // Call handler right away so state gets updated with initial window size
    changeNavbarColor()
    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', changeNavbarColor)
  }, [])

  const navShadow = `
    -webkit-box-shadow: ${colorChange? '0 3px 5px rgba(57, 63, 72, 0.3)' : 'none'} !important;
    -moz-box-shadow: ${colorChange? '0 3px 5px rgba(57, 63, 72, 0.3)' : 'none'} !important;
    box-shadow: ${colorChange? '0 3px 5px rgba(57, 63, 72, 0.3)' : 'none'} !important;
  `;
  
  return (
    <Grid
      css={css`
        margin: 0 !important;
      `}
    >
      <Grid.Column
        only="computer"
        computer={16}
        css={css`
          margin-top: 108px;
          padding: 0 !important;
        `}
      >
        <SemanticMenu
          fixed="top"
          stackable
          css={css`
            padding: 0px 100px !important;
            background-color: ${colorChange
              ? isInverted
                ? theme.primary
                : theme.white
              : 'transparent'} !important;
            ${navShadow}
            border: none !important;
            border-radius: 0 !important;
            height: 108px !important;
            .item:before {
              background: transparent !important;
            }
          `}
        >
          <SemanticMenu.Item
            css={css`
              padding: 0 !important;
            `}
          >
            <Link href={'/'} passHref>
              <a
                css={css`
                  cursor: pointer;
                `}
              >
                <Image
                  src={require(isInverted
                    ? '../../assets/logo-inverted.png'
                    : '../../assets/logo.png')}
                  css={css`
                    width: 43.01px;
                    height: 60px;
                    pointer-events: none;
                  `}
                />
              </a>
            </Link>
          </SemanticMenu.Item>
          <WebNavigation navigation={navigation} {...props} />
        </SemanticMenu>
      </Grid.Column>
      <Grid.Column
        only="mobile tablet"
        mobile={16}
        tablet={16}
        css={css`
          padding: 0 !important;
        `}
      >
        <SemanticMenu
          fixed="top"
          css={css`
            padding: 0px 19px !important;
            background-color: ${isInverted
              ? colorChange
                ? theme.primary
                : 'transparent'
              : theme.white} !important;
            ${navShadow}
            border: none !important;
            border-radius: 0 !important;
            height: 108px !important;
            .item:before {
              background: transparent !important;
            }
          `}
        >
          <SemanticMenu.Item
            css={css`
              padding: 0 !important;
            `}
          >
            <Link href={'/'} passHref>
              <a
                css={css`
                  cursor: pointer;
                `}
              >
                <Image
                  src={require(isInverted
                    ? '../../assets/logo-inverted.png'
                    : '../../assets/logo.png')}
                  css={css`
                    width: 43.01px;
                    height: 60px;
                    pointer-events: none;
                  `}
                />
              </a>
            </Link>
          </SemanticMenu.Item>
          <MobileNavigation navigation={navigation} {...props} />
        </SemanticMenu>
      </Grid.Column>
    </Grid>
  )
}

export const Menu = withTheme(MenuComponent)
