import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'
import { Grid, Image } from 'semantic-ui-react'
import { map } from 'lodash'
import { FOOTER_ROUTES } from '../../../../constant'
import { SocialLinks } from './SocialLinks'
import { getMainRoute } from '../../../../util/util'

interface IFooterWebProps {
  theme: ITheme
  navigation: string
  isNav?: boolean
}

const FooterWebComponent: FC<IFooterWebProps> = (props) => {
  const { theme, navigation, isNav=true } = props
  return (
    <>
      <Grid.Column
        only="computer"
        computer={3}
        verticalAlign="middle"
        css={css`
          padding: 0 !important;
          padding-left: 205px !important;
        `}
      >
        <Link href={'/'} passHref>
          <Image src={require('../../assets/logo-footer.svg')} css={css`pointer-events: none;`}/>
        </Link>
      </Grid.Column>
      <Grid.Column
        only="computer"
        computer={10}
        verticalAlign="middle"
        textAlign="center"
        css={css`
          padding: 0 !important;
        `}
      >
        {isNav && <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          {map(FOOTER_ROUTES, (route, i) => {
            const mainRoute = getMainRoute(route.path)
            return (
              <Link key={i} href={route.path} passHref>
                <a
                  css={css`
                    margin-left: 24px;
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    color: ${theme.black};
                    opacity: 0.75;
                    text-decoration: ${navigation === mainRoute
                      ? 'underline'
                      : 'none'};
                    font-weight: ${navigation === mainRoute
                      ? 'bold'
                      : 'normal'};
                    text-decoration-thickness: 2px;
                    cursor: pointer;
                    &:hover {
                      color: ${theme.black};
                      text-decoration: underline;
                      text-decoration-thickness: 2px;
                    }
                  `}
                >
                  {route.title}
                </a>
              </Link>
            )
          })}
        </div>}
        <div
          css={css`
            margin-top: 16px !important;
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: ${theme.black};
              opacity: 0.65;
            `}
          >
            Copyright © 2008-2013 Royal College Old Boys in Western Australia
            Association (RCOBAWA). All Rights Reserved.
          </label>
          <br />
          <span
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: ${theme.black};
            `}
          >
            <label css={css`opacity: 0.65;`}>Powered by</label>&nbsp;
            <Link href='https://www.humanisedtech.com/member-management/' passHref>
              <a css={css`color: ${theme.black}; opacity: 0.65; :hover{color: ${theme.black}; opacity: 1;}`} target="_blank" rel="noopener noreferrer">
                HumanisedTech
              </a>
            </Link>
          </span>

        </div>
      </Grid.Column>
      <Grid.Column
        only="computer"
        computer={3}
        verticalAlign="middle"
        css={css`
          padding: 0 !important;
          display: flex !important;
          flex-direction: row !important;
          @media (max-width: 1024px) {
            display: none!important;
          }
        `}
      >
        <SocialLinks />
      </Grid.Column>
    </>
  )
}

export const FooterWeb = withTheme(FooterWebComponent)
