import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'

interface IAboutSummaryProps {
  containerMarginTop?: string
  organisation: string
  heading?: string
  content: string
  theme: ITheme
}

const AboutSummaryComponent: FC<IAboutSummaryProps> = (props) => {
  const {
    organisation,
    heading,
    content,
    containerMarginTop = '0px',
    theme,
  } = props
  return (
    <div
      css={css`
        background-color: ${theme.primary};
        min-height: 413px;
        padding: 0px 100px;
        margin-top: ${containerMarginTop};
        @media (max-width: 991px) {
          margin-top: 40px;
          padding: 0px 16px 0px 16px !important;
        }
      `}
    >
      <div
        css={css`
          padding: 64px 0px;
          display: flex;
          flex-direction: column;
          max-width: 927px;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: ${theme.stroke};
          `}
        >
          {organisation}
        </label>
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02px;
            color: ${theme.white};
          `}
        >
          {heading}
        </label>
        <label
          css={css`
            margin-top: 24px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: ${theme.white};
            white-space: pre-line;
          `}
        >
          {content}
        </label>
      </div>
    </div>
  )
}

export const AboutSummary = withTheme(AboutSummaryComponent)
