import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid } from 'semantic-ui-react'
import { ViewMore } from './Sections/ViewMore'
import { CardContent } from './Sections/CardContent'
import { CardInfo } from './Sections/CardInfo'
import { CardImage } from './Sections/CardImage'
import { INews } from '../../../../@types'
import { getSlug } from '../../../../util/util'

interface INewsCardComponentProps {
  spaceBottom?: string
  only: string
  data: INews
}

const NewsCardComponent: FC<INewsCardComponentProps> = (props) => {
  const { spaceBottom = '0px', data, only } = props
  return (
    <Grid.Column
      css={css`
        padding: 0px 50px ${spaceBottom} 0px !important;
      `}
      only={only}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;

          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
        `}
      >
        <CardImage image={data.image} />
        <CardInfo published={data.createdAt} />
        <CardContent
          path={`/media/news/${data.id}/${getSlug(data.title)}`}
          title={data.title}
          description={data.content}
          htmlContent
        />
        <ViewMore
          path={`/media/news/${data.id}/${getSlug(data.title)}`}
          viewText="Read More"
        />
      </div>
    </Grid.Column>
  )
}

export const NewsCard = withTheme(NewsCardComponent)
