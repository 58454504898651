import React, { FC, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers, FormikValues } from 'formik'
import * as yup from 'yup'
import { Checkbox, Form } from 'semantic-ui-react'
import { Dropdown, Button, Input, BraintreeDropIn } from '../../components'
import { RootState } from '../../../../redux/reducer'
import { ITicketPurchase, purchaseTicket } from '../../../../redux/actions'
import { IEvent, ITheme } from '../../../../@types'
import { FormContainer } from './FormContainer'
import { ErrorMsg } from './ErrorMsgContainer'
import LockImg from '../../assets/lock.svg'
import { times } from 'lodash'
import { SuccessContainer } from './SuccessContainer'

// form validationSchema
const validationSchema = yup.object().shape({
  numberOfTickets: yup.string().required('Please select ticket count'),
})

const validationSchemaPublic = yup.object().shape({
  numberOfTickets: yup
    .string()
    .label('Ticket Count')
    .required('Please select ticket count'),
  guestFirstName: yup
    .string()
    .label('First Name')
    .required('Please enter email address'),
  guestLastName: yup
    .string()
    .label('Last Name')
    .required('Please enter email address'),
  guestEmail: yup
    .string()
    .label('Email')
    .required('Please enter email address')
    .email('Please enter valid email address'),
})

interface IFormContainer {
  theme: ITheme
  event: IEvent
  freeEvent?: boolean
}
export interface ITicketPurchaseForm extends FormikValues {
  numberOfTickets: string
  guestFirstName?: string
  guestLastName?: string
  guestEmail?: string
}

const TicketPurchaseComponent: FC<IFormContainer> = (props) => {
  const router = useRouter()
  const [terms, setTerms] = useState(false)
  const [success, setSuccess] = useState(false)
  const [braintree, setBraintree] = useState(null)
  const user_id = localStorage.getItem('user_id')
  const { theme, event, freeEvent = false } = props
  const maxTickets = times(event.maxTicketPerMember, (item) => ({
    key: item,
    text: String(item + 1),
    value: String(item + 1),
  }))

  const loading = useSelector((state: RootState) => state.event.purchaseLoading)
  const error = useSelector((state: RootState) => state.event.purchaseError)
  const pdfUrl = useSelector((state: RootState) => state.event.pdfUrl)
  const dispatch = useDispatch()

  const formSubmit = async (
    data: ITicketPurchaseForm,
    formikHelper: FormikHelpers<ITicketPurchaseForm>
  ) => {
    const form: ITicketPurchase = {
      ...data,
      amount: Number(data.numberOfTickets) * event.ticketPrice,
      eventId: String(event.id),
      paymentMode: 'CARD',
      paymentType: 'EVENT',
    }
    if (!freeEvent) {
      // Send the nonce to your server
      const { nonce } = await braintree.requestPaymentMethod()
      form.paymentMethodNonce = nonce
    }
    if (user_id) {
      form.userId = user_id
    }
    await dispatch(purchaseTicket(form))
    formikHelper.resetForm({})
    setSuccess(true)
  }

  useEffect(() => {
    if (success) {
      router.replace(router.asPath)
    }
  }, [success])

  useEffect(() => {
    if (error) {
      braintree.clearSelectedPaymentMethod()
    }
  }, [error])

  return (
    <>
      <div
        css={css`
          display: ${success ? '' : 'none'};
        `}
      >
        <SuccessContainer
          containerMarginTop="84px"
          title="🙌🏻 Successfully Purchased!"
          description="You have succefully purchased a ticket. Please click on download button before coming to event. Ticket(s) will be scanned at the door to gain entrance."
          customContent={
            <div
              css={css`
                margin-top: 52px;
              `}
            >
              <Button
                onClick={() => window.open(pdfUrl, '_blank')}
                content="Download the ticket"
                fluid
              />
            </div>
          }
        />
      </div>
      <div
        css={css`
          display: ${success ? 'none' : ''};
        `}
      >
        <FormContainer
          containerPaddingTop="84px"
          formHeading={
            freeEvent ? 'Get your complimentary tickets' : 'Secured Payment'
          }
          formInfo={
            freeEvent
              ? 'Avail yourself of complimentary tickets for this free event for RCOBAWA members.'
              : 'We only accept card payments at the moment'
          }
          formHeadingIcon={freeEvent ? null : LockImg}
        >
          {error && <ErrorMsg error={error} />}
          <Formik
            initialValues={
              event.accessType === 'public'
                ? {
                    numberOfTickets: String(maxTickets[0].value),
                    guestFirstName: '',
                    guestLastName: '',
                    guestEmail: '',
                  }
                : {
                    numberOfTickets: String(maxTickets[0].value),
                  }
            }
            validationSchema={
              event.accessType === 'public'
                ? validationSchemaPublic
                : validationSchema
            }
            onSubmit={(
              values: ITicketPurchaseForm,
              formikHelper: FormikHelpers<ITicketPurchaseForm>
            ) => {
              formSubmit(values, formikHelper)
              setTimeout(() => {
                formikHelper.setSubmitting(false)
              }, 500)
            }}
          >
            {(props: FormikProps<ITicketPurchaseForm>) => {
              const {
                values,
                setFieldValue,
                handleChange,
                handleBlur,
                isSubmitting,
                submitForm,
              } = props
              return (
                <Form
                  css={css`
                    width: 505px;
                  `}
                  onSubmit={submitForm}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      @media (max-width: 991px) {
                        flex-direction: column;
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        margin-right: 16px;
                        @media (max-width: 991px) {
                          margin-right: 0;
                        }
                      `}
                    >
                      <Dropdown
                        selectOnBlur={false}
                        options={maxTickets}
                        type="select"
                        name="numberOfTickets"
                        label="Ticket Count"
                        placeholder="Select Ticket Count"
                        value={values.numberOfTickets}
                        onChange={(e, { value }) =>
                          setFieldValue('numberOfTickets', value)
                        }
                        selection
                        width="100%"
                        search
                        marginBottom="24px"
                      />
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        @media (max-width: 991px) {
                          margin-bottom: 24px;
                        }
                      `}
                    >
                      {freeEvent ? null : (
                        <>
                          <label
                            css={css`
                              font-family: ${theme.fontPrimary};
                              font-style: normal;
                              font-weight: normal;
                              font-size: 16px;
                              line-height: 24px;
                              color: ${theme.black};
                            `}
                          >
                            Total Price
                          </label>
                          <label
                            css={css`
                              margin-top: 8px;
                              font-family: ${theme.fontSecondary};
                              font-style: normal;
                              font-weight: 600;
                              font-size: 32px;
                              line-height: 40px;
                              letter-spacing: -0.02px;
                              color: ${theme.black};
                            `}
                          >
                            $
                            {Number(values.numberOfTickets) * event.ticketPrice}
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  {event.accessType === 'public' && (
                    <div>
                      <div
                        css={css`
                          display: flex;
                          flex-direction: row;
                          div {
                            flex: 1;
                          }
                          div:first-child {
                            margin-right: 16px !important;
                          }
                          @media (max-width: 991px) {
                            flex-direction: column;
                            div:first-child {
                              margin-right: 0px !important;
                            }
                          }
                        `}
                      >
                        <Input
                          type="text"
                          name="guestFirstName"
                          label="First Name"
                          placeholder="Enter your first name"
                          value={values.guestFirstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          fluid={true}
                          marginBottom="24px"
                        />
                        <Input
                          type="text"
                          name="guestLastName"
                          label="Last Name"
                          placeholder="Enter your last name"
                          value={values.guestLastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          fluid={true}
                          marginBottom="24px"
                        />
                      </div>
                      <Input
                        type="email"
                        name="guestEmail"
                        label="Email"
                        placeholder="yourname@mail.com"
                        value={values.guestEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        fluid={true}
                      />
                    </div>
                  )}
                  {freeEvent ? null : (
                    <div
                      css={css`
                        margin-top: 48px;
                      `}
                    >
                      <BraintreeDropIn setBraintree={setBraintree} />
                    </div>
                  )}
                  <div
                    css={css`
                      margin-top: 40px;
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <div
                      css={css`
                        margin-bottom: 20px;
                      `}
                    >
                      <Checkbox
                        label={
                          <label>
                            I agree with the &nbsp;
                            <Link href={'/termsAndConditions'} passHref>
                              <a
                                css={css`
                                  cursor: pointer;
                                `}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </Link>
                          </label>
                        }
                        id="terms"
                        checked={terms}
                        onChange={(e, { checked }) => setTerms(checked)}
                      />
                    </div>
                    <Button
                      content={freeEvent ? 'Confirm' : 'Purchase'}
                      type="submit"
                      fluid={true}
                      loading={loading}
                      disabled={loading || isSubmitting || !terms}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </FormContainer>
      </div>
    </>
  )
}

export const TicketPurchaseForm = withTheme(TicketPurchaseComponent)
