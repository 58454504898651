import axios from 'axios'
import { IMembership } from '../../../@types'
import { showError } from '../../../util/showError'
import { AppThunk } from '../../types'
import { registerCompletedAction, renewMembershipAction } from '../auth/action'
import { MembershipPurchaseAction, GetMembershipsAction } from './reducer'
import { MemberShipTypes } from './types'

export const membershipPurchaseLoadingAction = (
  loading: boolean
): MembershipPurchaseAction => ({
  type: MemberShipTypes.MEMBERSHIP_PURCHASE_LOADING,
  loading,
})

export const membershipPurchaseErrorAction = (
  error: string
): MembershipPurchaseAction => ({
  type: MemberShipTypes.MEMBERSHIP_PURCHASE_ERROR,
  error,
})

export interface IMembershipOnboard {
  paymentMethodNonce: string
  amount: number
  membershipTypeId: number
  paymentMode: string
  paymentType: string
  userId: string
}

export const membershipPurchase =
  (data: IMembershipOnboard): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(membershipPurchaseErrorAction(null))
      dispatch(membershipPurchaseLoadingAction(true))
      await axios.post('/payment/membership', data)
      dispatch(registerCompletedAction(null))
      dispatch(renewMembershipAction(null))
    } catch (error) {
      const errMsg = showError(error)
      dispatch(membershipPurchaseErrorAction(errMsg))
      throw errMsg
    } finally {
      dispatch(membershipPurchaseLoadingAction(false))
    }
  }

export const getMembershipsLoadingAction = (
  loading: boolean
): GetMembershipsAction => ({
  type: MemberShipTypes.GET_MEMBERSHIPS_LOADING,
  loading,
})

export const getMembershipsErrorAction = (
  error: string
): GetMembershipsAction => ({
  type: MemberShipTypes.GET_MEMBERSHIPS_ERROR,
  error,
})

export const getMembershipsSuccessAction = (
  memberships: Array<IMembership>
): GetMembershipsAction => ({
  type: MemberShipTypes.GET_MEMBERSHIPS_SUCCESS,
  memberships,
})

export const getMemberships = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getMembershipsErrorAction(null))
    dispatch(getMembershipsLoadingAction(true))
    const response = await axios.get('/users/membershipTypes')
    dispatch(getMembershipsSuccessAction(response.data))
  } catch (error) {
    dispatch(getMembershipsErrorAction(showError(error)))
  } finally {
    dispatch(getMembershipsLoadingAction(false))
  }
}
