import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { Input, PasswordInput, Button } from '../../components'
import { ILogInForm, loginUser } from '../../../../redux/actions'
import { RootState } from '../../../../redux/reducer'
import { ITheme } from '../../../../@types'
import { FormContainer } from './FormContainer'
import { ErrorMsg } from './ErrorMsgContainer'

// form validationSchema
const validationSchema = yup.object().shape({
  username: yup
    .string()
    .label('Email')
    .required('Please enter email address')
    .email('Please enter valid email address'),
  password: yup
    .string()
    .label('Password')
    .required('Please enter valid password'),
})

interface IFormContainer {
  theme: ITheme
  setForm: (value: string) => void
  setMembershipType: (value: number) => void
}

const SignInComponent: FC<IFormContainer> = (props) => {
  const { theme, setForm, setMembershipType } = props
  const loading = useSelector((state: RootState) => state.auth.loading)
  const error = useSelector((state: RootState) => state.auth.error)
  const membershipType = useSelector(
    (state: RootState) => state.auth.membershipType
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (membershipType) {
      setMembershipType(membershipType)
      setForm('renewMembership')
    }
  }, [membershipType])

  const formSubmit = async (
    data: ILogInForm,
    formikHelper: FormikHelpers<ILogInForm>
  ) => {
    await dispatch(loginUser(data))
    formikHelper.resetForm({})
  }

  return (
    <FormContainer
      containerPaddingTop="84px"
      formHeading="Hi, Welcome Back"
      formInfo="Nice to see you again, Login to continue"
    >
      {error && <ErrorMsg error={error} />}
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(
          values: ILogInForm,
          formikHelper: FormikHelpers<ILogInForm>
        ) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<ILogInForm>) => {
          const { values, handleBlur, handleChange, isSubmitting, submitForm } =
            props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <Input
                type="email"
                name="username"
                label="Email"
                placeholder="yourname@mail.com"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <PasswordInput
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <div
                css={css`
                  margin-bottom: 40px;
                  text-align: left;
                `}
              >
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: ${theme.black};
                    cursor: pointer;
                  `}
                  onClick={() => setForm('forgotPassword')}
                >
                  Forgot Password?
                </label>
              </div>
              <Button
                content="Login"
                type="submit"
                fluid={true}
                loading={loading}
                disabled={loading || isSubmitting}
              />
              <div
                css={css`
                  margin-top: 16px;
                  text-align: left;
                `}
              >
                <span
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.black};
                  `}
                >
                  Want to join RCOBAWA?{' '}
                  <label
                    css={css`
                      text-decoration-line: underline;
                      cursor: pointer;
                    `}
                    onClick={() => setForm('signUp')}
                  >
                    Become a Member
                  </label>
                </span>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const SignInForm = withTheme(SignInComponent)
