import React, { FC, ReactNode } from 'react'
import { css, withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'

interface IFormContainerProps {
  containerPaddingTop: string
  containerPaddingBottom?: string
  theme: ITheme
  formPage?: string
  formHeading: string
  formHeadingIcon?: string
  formInfo?: string
  children: ReactNode
}

const FormContainerComponent: FC<IFormContainerProps> = (props) => {
  const {
    containerPaddingTop: containerMarginTop = '80px',
    containerPaddingBottom: containerMarginBottom = '0px',
    formPage,
    formHeading,
    formInfo,
    formHeadingIcon: FormIcon,
    theme,
    children
  } = props

  return (
    <div
      css={css`
        background-color: ${theme.white};
        padding-top: ${containerMarginTop};
        padding-bottom: ${containerMarginBottom};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `}
      >
        {formPage && (
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${theme.textSecondary};
              word-wrap: break-word;
            `}
          >
            {formPage}
          </label>
        )}
        <span
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02px;
            color: ${theme.primary};
            word-wrap: break-word;
            display: flex;
            align-items: center;
            svg {
              margin-right: 14.67px;
            }
          `}
        >
          {FormIcon && <FormIcon />} <label>{formHeading}</label>
        </span>
        {formInfo && (
          <label
            css={css`
              margin-top: 8px;
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${theme.textSecondary};
              word-wrap: break-word;
            `}
          >
            {formInfo}
          </label>
        )}
      </div>
      <div
        css={css`
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `}
      >
        {children}
      </div>
    </div>
  )
}

export const FormContainer = withTheme(FormContainerComponent)
