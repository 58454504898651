import axios from 'axios'
import Router from 'next/router'
import * as FullStory from '@fullstory/browser';
import { AppThunk } from '../../types'
import { inquiryErrorAction } from '../support/action'
import { eventPurchaseErrorAction, eventPurchaseLoadingAction, eventPurchaseSuccessAction } from '../event/action'
import { getMemberships, membershipPurchaseErrorAction, membershipPurchaseLoadingAction } from '../membership/action'
import {
  loginErrorAction,
  forgotErrorAction,
  resetErrorAction,
  registerErrorAction,
  registerCompletedAction,
  renewMembershipAction,
  loginLoadingAction,
  forgotLoadingAction,
  resetLoadingAction,
  registerLoadingAction,
} from '../auth/action'

export const resetForms = (): AppThunk => async (dispatch) => {
  try {
    await Promise.all([
      // errors reset
      dispatch(loginErrorAction(null)),
      dispatch(forgotErrorAction(null)),
      dispatch(resetErrorAction(null)),
      dispatch(registerErrorAction(null)),
      dispatch(eventPurchaseErrorAction(null)),
      dispatch(inquiryErrorAction(null)),
      dispatch(membershipPurchaseErrorAction(null)),
      dispatch(eventPurchaseErrorAction(null)),
      // loading reset
      dispatch(loginLoadingAction(null)),
      dispatch(forgotLoadingAction(null)),
      dispatch(resetLoadingAction(null)),
      dispatch(registerLoadingAction(null)),
      dispatch(registerCompletedAction(null)),
      dispatch(membershipPurchaseLoadingAction(null)),
      dispatch(renewMembershipAction(null)),
      dispatch(eventPurchaseLoadingAction(null)),
      dispatch(eventPurchaseSuccessAction(null)),
    ])
  } catch (error) {
    console.error(error)
  }
}

export const initializeApp = (userToken: string): any => async (dispatch) => {
  try {
    if(userToken){
      axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
    }
    await Promise.all([
      dispatch(getMemberships())
    ])
  } catch (error) {
    console.error(error)
  }
}

export const logoutUser = (): any => async () => {
  try {
    localStorage.clear()
    axios.defaults.headers.common['Authorization'] = null;
    Router.push('/')
  } catch (error) {
    console.error(error)
  }
}

export const getUser = (): any => async () => {
  try {
    const response= await axios.get('/users/me')
    FullStory.identify(response.data.id, {
      id: response.data.id,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      email: response.data.email,
      membershipType: response.data.membershipType,
    });
  } catch (error) {
    console.error(error)
  }
}
