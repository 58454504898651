export enum AuthTypes {
    LOGIN_LOADING = 'LOGIN_LOADING',
    LOGIN_COMPLETED = 'LOGIN_COMPLETED',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT_FROM_APP = 'LOGOUT_FROM_APP',
    FORGOT_LOADING = 'FORGOT_LOADING',
    FORGOT_ERROR = 'FORGOT_ERROR',
    RESET_LOADING = 'RESET_LOADING',
    RESET_ERROR = 'RESET_ERROR',
    REGISTER_LOADING = 'REGISTER_LOADING',
    REGISTER_COMPLETED = 'REGISTER_COMPLETED',
    REGISTER_ERROR = 'REGISTER_ERROR',
    RENEW_MEMBERSHIP = 'RENEW_MEMBERSHIP',
}