import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { Input, Button } from '../../components'
import { IForgotPasswordForm, forgotPassword } from '../../../../redux/actions'
import { RootState } from '../../../../redux/reducer'
import { ITheme } from '../../../../@types'
import { FormContainer } from './FormContainer'
import { ErrorMsg } from './ErrorMsgContainer'
import { SuccessContainer } from './SuccessContainer'

// form validationSchema
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .required('Please enter email address')
    .email('Please enter valid email address'),
})

interface IFormContainer {
  theme: ITheme
  setForm: (value: string) => void
}

const ForgotPasswordComponent: FC<IFormContainer> = (props) => {
  const [success, setSuccess] = useState(null)
  const {
    theme,
    setForm,
  } = props
  const loading = useSelector((state: RootState) => state.auth.forgotLoading)
  const error = useSelector((state: RootState) => state.auth.forgotError)
  const dispatch = useDispatch()

  const formSubmit = async (
    data: IForgotPasswordForm,
    formikHelper: FormikHelpers<IForgotPasswordForm>
  ) => {
    await dispatch(forgotPassword(data))
    formikHelper.resetForm({})
    setSuccess(data.email)
  }

  return success ? (
    <SuccessContainer
      containerMarginTop="84px"
      title="Forgot password?"
      info="Don’t worry, we got you."
      customContent={
        <div
          css={css`
            margin-top: 24px;
            text-align: left;
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${theme.textSecondary};
            `}
          >
            We sent a recovery link to you at {success}
          </label>
          <div
            css={css`
              margin-top: 40px;
              text-align: left;
            `}
          >
            <label
              css={css`
                margin-top: 40px;
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: ${theme.primary};
                text-decoration-line: underline;
                cursor: pointer;
              `}
              onClick={() => setForm('signIn')}
            >
              Go back to Sign In
            </label>
          </div>
        </div>
      }
    />
  ) : (
    <FormContainer
      containerPaddingTop="84px"
      formHeading="Forgot password?"
      formInfo="Don’t worry, we got you."
    >
      {error && <ErrorMsg error={error} />}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(
          values: IForgotPasswordForm,
          formikHelper: FormikHelpers<IForgotPasswordForm>
        ) => {
          formSubmit(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IForgotPasswordForm>) => {
          const { values, handleBlur, handleChange, isSubmitting, submitForm } =
            props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <div
                css={css`
                  margin-bottom: 24px;
                  text-align: left;
                `}
              >
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.textSecondary};
                  `}
                >
                  Enter the email address you used when you joined and we willl
                  email you instructions to reset your password.
                </label>
              </div>
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="yourname@mail.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="40px"
              />
              <Button
                content="Reset Password"
                type="submit"
                fluid={true}
                loading={loading}
                disabled={loading || isSubmitting}
              />
              <div
                css={css`
                  margin-top: 16px;
                  text-align: left;
                `}
              >
                <label
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: ${theme.primary};
                    text-decoration-line: underline;
                    cursor: pointer;
                  `}
                  onClick={() => setForm('signIn')}
                >
                  Go back to Sign In
                </label>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const ForgotPasswordForm = withTheme(ForgotPasswordComponent)
