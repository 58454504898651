import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Image, Segment, TransitionablePortal } from 'semantic-ui-react'
import { resetForms } from '../../../../redux/actions'
import LandingImage from '../../assets/landing-poster.webp'
import { ITheme } from '../../../../@types'
import { OverlayHeading } from './OverlayHeading'

interface IContainerProps {
  onClick?: () => void
}
interface IOverlayModalProps {
  theme: ITheme
  trigger?: ReactElement | undefined
  heading: string
  description?: string | undefined
  emailTo?: string | undefined
  socialLinks?: boolean | undefined
  date?: string | undefined
  time?: string | undefined
  location?: string | undefined
  containerProps?: IContainerProps
  children?: ReactElement | undefined
  animation?: string
  duration?: number
}

const OverlayModalComponent: FC<IOverlayModalProps> = (props) => {
  const {
    theme,
    trigger,
    heading,
    description,
    emailTo,
    socialLinks,
    date,
    time,
    location,
    children,
    containerProps,
    animation = 'slide left',
    duration = 500,
  } = props
  const [modal, setModal] = useState(trigger ? false : true)
  const dispatch = useDispatch()

  useEffect(() => {
    // used to remove all error messages from overlay modal forms when it opens
    if (modal) {
      document.getElementsByTagName("body")[0].style.overflowY = 'hidden';
      dispatch(resetForms())
    }else{
      document.getElementsByTagName("body")[0].style.overflowY = '';
    }
  }, [modal])

  const triggerClicked = () => {
    setModal(true)

    // fire custom onclick func passes from parent component
    if(containerProps && containerProps.onClick){
      containerProps.onClick()
    }
  }

  const headingProps = {
    heading,
    description,
    emailTo,
    socialLinks,
    date,
    time,
    location,
  }

  return (
    <div>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: triggerClicked,
        })}
      <TransitionablePortal closeOnDocumentClick={false} open={modal} transition={{ animation, duration }}>
        <Segment
          style={{
            //do not remove these even its written in style prop
            right: '0%',
            position: 'fixed',
            top: '0%',
            zIndex: 101,
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }}
          basic
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              height: 100%;
              width: 100%;
              background-color: white;
            `}
          >
            <div
              css={css`
                background: linear-gradient(
                    270deg,
                    rgba(0, 0, 0, 0) 50%,
                    rgba(16, 39, 112, 0.9) 100%
                  ),
                  url(${LandingImage});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width: 56%;
                @media (max-width: 1024px) {
                  width: 0%;
                  display: none;
                }
              `}
            >
              <OverlayHeading {...headingProps} />
            </div>
            <div
              css={css`
                width: 44%;
                background-color: ${theme.white};
                overflow: auto;
                @media (max-width: 1024px) {
                  width: 100%;
                }
                @media (max-width: 760px) {
                  ::-webkit-scrollbar {
                    width: 0;
                  }
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  padding: 28px 100px 28px 105px;
                  @media (max-width: 1024px) {
                    width: 100%;
                    align-items: center;
                  }
                  @media (max-width: 767px) {
                    padding: 28px 16px 56px 16px !important;
                    align-items: unset;
                  }
                `}
                id="overlayModal"
              >
                <div
                  css={css`
                    cursor: pointer;
                    align-self: flex-end;
                    height: 48px;
                  `}
                  onClick={trigger ? () => setModal(false) : undefined}
                >
                  {trigger && (
                    <Image
                      src={require('../../assets/modal-close.svg')}
                      css={css`
                        width: 48px;
                        height: 48px;
                        pointer-events: none;
                      `}
                    />
                  )}
                </div>
                {children &&
                  React.cloneElement(children, {
                    key: Number(modal),
                  })}
              </div>
            </div>
          </div>
        </Segment>
      </TransitionablePortal>
    </div>
  )
}

export const OverlayModal = withTheme(OverlayModalComponent)
