import React, { FC } from 'react'
import { OverlayModal, ResetPasswordForm } from '../components'


export const ResetPasswordPage: FC = () => {
  return (
    <OverlayModal
      heading="Royal College Old Boys' Association Western Australia"
      description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."   
      duration={0}   
    >
      <ResetPasswordForm />
    </OverlayModal>
  )
}
export const CreatePasswordPage: FC = () => {
  return (
    <OverlayModal
      heading="Royal College Old Boys' Association Western Australia"
      description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."   
      duration={0}   
    >
      <ResetPasswordForm create />
    </OverlayModal>
  )
}