import React, { FC, useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Grid, GridProps } from 'semantic-ui-react'
import { map, isEmpty, drop } from 'lodash'
import { useWindowSize } from '../../../../util/useWindowSize'
import { EmptyContainer } from '../EmptyContainer'
interface IChildProps<T> {
  data: T
  spaceBottom?: string
  only: string
  fullWidth?: boolean
}
interface ICardContainerComponentProps<T> extends GridProps {
  containerMarginTop: string
  dataSet?: Array<T>
  Card?: FC<IChildProps<T>>
  spaceBottom?: string
  horizontalScroll?: boolean
  Header?: FC<IChildProps<T>>
  emptyMessage: string
  stackable?: boolean
}

export function CardContainer<T>(
  props: ICardContainerComponentProps<T>
): JSX.Element {
  const {
    containerMarginTop,
    spaceBottom,
    columns,
    dataSet,
    Card,
    horizontalScroll = false,
    Header,
    emptyMessage,
    fullWidth = false,
    stackable = true,
  } = props
  const { isTablet } = process.browser && useWindowSize()

  const [columnCount, setColumnCount] = useState(columns)

  useEffect(() => {
    // reduced column count by 1 if window size lower than tablet and provided count is 3
    if (isTablet && columnCount === 3) {
      setColumnCount(2)
    }
  }, [])

  return (
    <div
      css={css`
        margin-top: ${containerMarginTop};
        padding: 0px 80px 0px 100px;
        @media (max-width: 991px) {
          margin-top: 40px;
          padding: 0px 16px !important;
        }
        @media (max-width: 767px) {
          padding: 0 !important;
        }
      `}
    >
      <Grid
        columns={columnCount}
        stackable={stackable}
        css={css`
          margin: 0 !important;
          @media (max-width: 767px) {
            ${horizontalScroll &&
            `
            flex-wrap: nowrap !important;
            overflow-x: scroll !important;
            -webkit-overflow-scrolling: touch !important;
            overflow-scrolling: touch !important;
            ::-webkit-scrollbar {
              height: 0;
            }
            `}
          }
        `}
      >
        {isEmpty(dataSet) ? (
          <EmptyContainer message={emptyMessage} />
        ) : (
          <>
            {Header ? (
              <>
                <Header data={dataSet[0]} only="computer" />
                {dataSet &&
                  map(drop(dataSet), (content, i) => (
                    <Card
                      key={i}
                      data={content}
                      spaceBottom={spaceBottom}
                      fullWidth={fullWidth}
                      only="computer"
                    />
                  ))}
                {dataSet &&
                  map(dataSet, (content, i) => (
                    <Card
                      key={i}
                      data={content}
                      spaceBottom={spaceBottom}
                      fullWidth={fullWidth}
                      only="tablet mobile"
                    />
                  ))}
              </>
            ) : (
              dataSet &&
              map(dataSet, (content, i) => (
                <Card
                  key={i}
                  data={content}
                  spaceBottom={spaceBottom}
                  fullWidth={fullWidth}
                  only="computer tablet mobile"
                />
              ))
            )}
          </>
        )}
      </Grid>
    </div>
  )
}
