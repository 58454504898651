import React, { FC } from 'react'
import { useField } from 'formik'
import { omit } from 'lodash'
import { css } from '@emotion/react'
import {
  Form,
  FormInput as SemanticInput,
  Icon,
  InputProps,
} from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'

interface JInputProps extends InputProps {
  theme: ITheme
  marginBottom: string
}

const CurrencyInputComponent: FC<JInputProps> = (props) => {
  const { theme, marginBottom } = props
  const [field, meta] = useField(props.name) // field is the same, meta contains field-level slices of state
  const hasError = meta.touched && meta.error
  return (
    <Form.Field
      css={css`
        margin-bottom: ${marginBottom} !important;
        label {
          display: flex !important;
        }
      `}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary} !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 24px !important;
          color: ${theme.black} !important;
        `}
      >
        {props.label}
      </label>
      <SemanticInput
        css={css`
          margin: 0 !important;
          input {
            height: 48px !important;
            border: 1px solid ${hasError ? theme.error : theme.stroke} !important;
            box-sizing: border-box !important;
            border-radius: 4px !important;
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 24px !important;
            background-color: ${theme.white} !important;
            ::placeholder {
              color: ${theme.textSecondary} !important;
              opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: ${theme.textSecondary} !important;
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: ${theme.textSecondary} !important;
            }
          }
          .ui[class*='left icon'].input > input {
            padding-left: 4em !important;
          }
        `}
        {...field}
        {...omit(props, ['label', 'theme', 'marginBottom', 'leftLabelText'])}
        error={meta.touched && Boolean(meta.error)}
        type="number"
        min="1"
        step="any"
        iconPosition="left"
        icon={
          <Icon
            link
            css={css`
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              padding: 0px 5px !important;
              width: 4rem !important;
              opacity: 1 !important;
              border-right: 1px solid ${hasError ? theme.error : theme.stroke} !important;
            `}
          >
            <label
              css={css`
                font-family: ${theme.fontSecondary};
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                text-align: center;
                color: ${theme.black};
              `}
            >
              {props.leftLabelText}
            </label>
          </Icon>
        }
      />
      {hasError && (
        <div
          css={css`
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 20px !important;
            color: ${theme.error} !important;
            margin-top: 4px !important;
            text-align: left !important;
          `}
        >
          {meta.error}
        </div>
      )}
    </Form.Field>
  )
}

export const CurrencyInput = withTheme(CurrencyInputComponent)
