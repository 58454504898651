import React, { FC, useEffect, useState } from 'react'
import dynamic from "next/dynamic"
import { css, withTheme } from '@emotion/react'
import { Segment } from 'semantic-ui-react'
import { DROP_IN_AUTH_KEY } from '../../../config';
import { isEmpty } from 'lodash';
const DropIn = dynamic(() => import('braintree-web-drop-in-react'), {ssr: false}); //load it on client side only

const dropInStyles = css`
  min-height: 260px;
  .braintree-show-card {
    margin-bottom: 30px;
  }
  .braintree-show-methods {
    margin-bottom: 30px;
  }
  .braintree-sheet {
    border: 0;
  }
  .braintree-sheet__header {
    border: 0;
    padding-left: 0;
  }
  .braintree-sheet__content {
    padding: 0;
  }
  .braintree-sheet__header-label {
    display: none;
  }
  .braintree-heading {
    display: none;
  }
  .braintree-methods {
    margin: 0;
    .braintree-method {
      width: 100%;
    }
  }
  .braintree-form__hosted-field {
    height: 48px !important;
    border-radius: 4px;
    border: 1px solid #bababa !important;
    box-sizing: border-box !important;
  }
  .braintree-sheet__error {
    background-color: white;
    margin: 0;
  }
  .braintree-large-button {
    background-color: white;
  }
  .braintree-form__field-group {
    padding: 0;
    .braintree-form__label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .braintree-form__flexible-fields {
    .braintree-form__field-group:first-child {
      padding-right: 16px;
    }
  }
  .braintree-sheet__icons {
    .braintree-sheet__card-icon:first-child {
      padding-left: 0;
    }
  }
  @media (max-width: 991px) {
    .braintree-form__flexible-fields {
      .braintree-form__field-group {
        padding-right: 0px !important;
      }
    }
  }
`

interface IDropIn {
  setBraintree: (instance: any) => void
}

const DropInComponent: FC<IDropIn> = ({ setBraintree }) => {
  const [dropIn, setDropIn] = useState(null)
  
  useEffect(() => {
    if(dropIn){
      setBraintree(dropIn)
    }
  }, [dropIn])

  return (
    <Segment
      css={css`
        padding: 0 !important;
      `}
      basic
      loading={isEmpty(dropIn)}
    >
      <label
        css={css`
          font-family: Manrope;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02px;
          color: #102770;
        `}
      >
        Payment Details
      </label>
      <div css={dropInStyles}>
        <DropIn
          options={{
            authorization: DROP_IN_AUTH_KEY,
            card: {
              overrides: {
                fields: {
                  number: {
                    placeholder: '1111 1111 1111 1111', // Update the number field placeholder
                  },
                },
              },
            },
          }}
          onInstance={(instance) => setDropIn(instance)}
          onError={(error) => console.error(error)}
        />
      </div>
    </Segment>
  )
}

export const BraintreeDropIn = withTheme(DropInComponent)
