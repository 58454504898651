import React, { FC } from 'react'
import {
  AboutHead,
  Footer,
  CoverPoster,
  JoinNowContainer,
  AboutContent
} from '../components'
import AboutCoverWebp from '../assets/about-cover.webp'
import AboutCoverJpg from '../assets/about-cover.jpg'
import GroupPhotoWebp from '../assets/group-photo.webp'
import GroupPhotoJpg from '../assets/group-photo.jpg'

export const AboutPage: FC = () => {
  return (
    <div>
      <AboutHead
        title="About RCOBAWA"
        description="The Royal College Old Boys Association of Western Australia (RCOBAWA) is an organisation incorporated in the state of Western Australia. Membership is open to all past students of Royal College (RC), Colombo, and even those who may have only attended the Royal Junior School, formerly known as Royal Primary School. RCOBAWA is recognised as the sole representative organisation in Western Australia of old boys of Royal College by the parent body in Sri Lanka; the Royal College Union (RCU)."
      />
      <main>
        <CoverPoster webp={AboutCoverWebp} jpg={AboutCoverJpg} height="36vw" objectFit="fill" />
        <AboutContent />
        <CoverPoster webp={GroupPhotoWebp} jpg={GroupPhotoJpg} height="29vw" objectFit="cover"/>
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer />
    </div>
  )
}
