import React, { FC } from 'react'
import { useField } from 'formik'
import { DateInput, YearInput } from 'semantic-ui-calendar-react'
import { css } from '@emotion/react'
import { Form, InputProps } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'
import { omit } from 'lodash'

interface JDatePickerProps extends InputProps {
  theme: ITheme
  marginBottom: string
  inputType: string
}

const DatePickerComponent: FC<JDatePickerProps> = (props) => {
  const { theme, marginBottom, inputType='date' } = props
  const [field, meta] = useField(props.name) // field is the same, meta contains field-level slices of state
  const hasError = meta.touched && meta.error

  const getInput = () => {
    switch (inputType) {
      case 'year':        
        return YearInput;    
      default:
        return DateInput;
    }
  }

  const InputComponent = getInput();

  return (
    <Form.Field
      css={css`
        margin-bottom: ${marginBottom} !important;
        label {
          display: flex !important;
        }
      `}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary} !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 24px !important;
          color: ${theme.black} !important;
        `}
      >
        {props.label}
      </label>
      <div
        css={css`
          .field {
            width: 100% !important;
            .input {
              input {
                padding: 0px 0px 0px 10px !important;
                height: 48px !important;
                border: 1px solid ${hasError ? theme.error : theme.stroke} !important;
                box-sizing: border-box !important;
                border-radius: 4px !important;
                font-family: ${theme.fontPrimary} !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                line-height: 24px !important;
                background-color: ${theme.white} !important;
                ::placeholder {
                  color: ${theme.textSecondary} !important;
                  opacity: 1; /* Firefox */
                }
                :-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: ${theme.textSecondary} !important;
                }
                ::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: ${theme.textSecondary} !important;
                }
              }
            }
          }
        `}
      >
        <InputComponent
          onChange={props.onChange}
          closable={true}
          hideMobileKeyboard={true}
          icon="calendar alternate"
          {...omit(field, ['onBlur', 'onChange'])}
          {...omit(props, ['label', 'theme', 'marginBottom', 'onChange', 'inputType'])}
          clearable
        />
      </div>
      {hasError && (
        <div
          css={css`
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 20px !important;
            color: ${theme.error} !important;
            margin-top: 4px !important;
            text-align: left !important;
          `}
        >
          {meta.error}
        </div>
      )}
    </Form.Field>
  )
}

export const DatePicker = withTheme(DatePickerComponent)
