import React, { FC } from 'react'
import { ISiteData } from '../../../@types/site'
import {
  DescriptionHead,
  Footer,
  JoinNowContainer,
  SectionContainer,
  CardContainer,
  EventCard,
} from '../components'

export const EventsPage: FC<ISiteData> = ({ eventList }) => {
  return (
    <div>
      <DescriptionHead
        title="RCOBAWA Events"
        description="The quintessential space for RCOBAWA events held year-round, stay on top of sporting encounters, charity events and all leisure activities involving the club, while purchasing tickets conveniently."
      />
      <main>
        <SectionContainer
          containerPaddingTop="80px"
          containerPaddingBottom="56px"
          page="Events"
          heading="Upcoming Events"
          content={
            <CardContainer
              containerMarginTop="64px"
              columns={3}
              dataSet={eventList}
              Card={EventCard}
              spaceBottom="65px"
              emptyMessage="No events scheduled for now. Please check at a later date."
            />
          }
        />
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer />
    </div>
  )
}
