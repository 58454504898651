import React, { FC, ReactElement } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import ArrowImg from '../assets/arrow.svg'
import { ITheme } from '../../../@types'
import { Breadcrumb } from './Breadcrumb'

interface ISectionContainerProps {
  containerPaddingTop: string
  containerPaddingBottom?: string
  page?: string
  location?: string
  inverted?: boolean
  singlePage?: boolean
  heading: string
  viewMoreLabel?: string | undefined
  viewMorePath?: string | undefined
  viewMoreToggle?: () => void
  theme: ITheme
  content?: ReactElement
}

const SectionContainerComponent: FC<ISectionContainerProps> = (props) => {
  const {
    containerPaddingTop: containerMarginTop = '80px',
    containerPaddingBottom: containerMarginBottom = '0px',
    page,
    location,
    heading,
    viewMoreLabel,
    viewMorePath,
    theme,
    content,
    inverted = false,
    singlePage = false,
    viewMoreToggle,
  } = props

  return (
    <div
      css={css`
        background-color: ${inverted ? theme.bgGray : theme.white};
        padding-top: ${containerMarginTop};
        padding-bottom: ${containerMarginBottom};
        @media (max-width: 991px) {
          padding-top: 80px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0px 108px 0px 100px;
          @media (max-width: 991px) {
            padding: 0px 16px 0px 16px !important;
          }
        `}
      >
        {page && (
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: ${theme.textSecondary};
              word-wrap: break-word;
            `}
          >
            {page}
          </label>
        )}
        {location && <Breadcrumb location={location} />}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `}
        >
          {singlePage ? (
            <h1
              css={css`
                font-family: ${theme.fontSecondary};
                font-style: normal;
                font-weight: 800;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -0.02px;
                color: ${theme.primary};
                word-wrap: break-word;
                margin: 0;
              `}
            >
              {heading}
            </h1>
          ) : (
            <h2
              css={css`
                font-family: ${theme.fontSecondary};
                font-style: normal;
                font-weight: 800;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -0.02px;
                color: ${theme.primary};
                word-wrap: break-word;
                margin: 0;
              `}
            >
              {heading}
            </h2>
          )}
          {viewMoreLabel && viewMoreToggle && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                @media (max-width: 991px) {
                  justify-content: center;
                  align-items: center;
                }
              `}
              onClick={() => viewMoreToggle()}
            >
              <label
                css={css`
                  margin-right: 13.07px;
                  font-family: ${theme.fontPrimary};
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.25px;
                  text-decoration-line: underline;
                  color: ${theme.primary};
                  cursor: pointer;
                  word-wrap: break-word;
                  flex: 1;
                `}
              >
                {viewMoreLabel}
              </label>
              <ArrowImg />
            </div>
          )}
          {viewMoreLabel && viewMorePath && (
            <Link href={viewMorePath} passHref>
              <a
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  @media (max-width: 991px) {
                    justify-content: center;
                    align-items: center;
                  }
                `}
              >
                <label
                  css={css`
                    margin-right: 13.07px;
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                    text-decoration-line: underline;
                    color: ${theme.primary};
                    cursor: pointer;
                    word-wrap: break-word;
                    flex: 1;
                  `}
                >
                  {viewMoreLabel}
                </label>
                <ArrowImg />
              </a>
            </Link>
          )}
        </div>
      </div>
      {content}
    </div>
  )
}

export const SectionContainer = withTheme(SectionContainerComponent)
