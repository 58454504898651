import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css, withTheme } from '@emotion/react'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Form } from 'semantic-ui-react'
import { Input, TextArea, Button } from '../../components'
import { IInquiryForm, sentInquiry } from '../../../../redux/actions'
import { RootState } from '../../../../redux/reducer'
import { REG_EXP_PHONE } from '../../../../constant'
import { FormContainer } from './FormContainer'
import { SuccessContainer } from './SuccessContainer'
import { ErrorMsg } from './ErrorMsgContainer'
import { ITheme } from '../../../../@types'

// form validationSchema
const validationSchema = yup.object().shape({
  name: yup.string().label('Name').required('Please enter name'),
  mobileNumber: yup
    .string()
    .matches(REG_EXP_PHONE, 'Phone number is not valid')
    .min(8, 'to short')
    .label('Phone Number')
    .required('Please enter phone number'),
  email: yup
    .string()
    .label('Email')
    .required('Please enter email address')
    .email('Please enter valid email address'),
  inquiry: yup.string().label('Message').required('Please enter message'),
})

interface IFormContainer {
  theme: ITheme
}

const InquiryFormComponent: FC<IFormContainer> = () => {
  const [success, setSuccess] = useState(false)
  const loading = useSelector((state: RootState) => state.support.loading)
  const error = useSelector((state: RootState) => state.support.error)
  const dispatch = useDispatch()

  const sendInquiry = async (
    data: IInquiryForm,
    formikHelper: FormikHelpers<IInquiryForm>
  ) => {
    // API call integration will be here. Handle success / error response accordingly.
    await dispatch(sentInquiry(data))
    if(!error){
      formikHelper.resetForm({})
      setSuccess(true)
    }
  }

  return success ? (
    <SuccessContainer
      containerMarginTop="84px"
      title="🙌🏻 We got your message"
      description="Thank you for contacting us. We’ll get back to you as soon as possible."
    />
  ) : (
    <FormContainer
      containerPaddingTop="84px"
      formPage="Contact Us"
      formHeading="Send your inquiry"
    >
      {error && <ErrorMsg error={error} />}
      <Formik
        initialValues={{ name: '', mobileNumber: '', email: '', inquiry: '' }}
        validationSchema={validationSchema}
        onSubmit={(
          values: IInquiryForm,
          formikHelper: FormikHelpers<IInquiryForm>
        ) => {
          sendInquiry(values, formikHelper)
          setTimeout(() => {
            formikHelper.setSubmitting(false)
          }, 500)
        }}
      >
        {(props: FormikProps<IInquiryForm>) => {
          const { values, handleBlur, handleChange, isSubmitting, submitForm } =
            props
          return (
            <Form
              css={css`
                width: 505px;
              `}
              onSubmit={submitForm}
            >
              <Input
                type="text"
                name="name"
                label="Name"
                placeholder="What is your name?"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <Input
                type="text"
                name="mobileNumber"
                label="Phone Number"
                placeholder="What is your phone number?"
                value={values.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="yourname@mail.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                fluid={true}
                marginBottom="24px"
              />
              <TextArea
                type="text"
                name="inquiry"
                label="Message"
                placeholder="Your message here"
                value={values.inquiry}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                marginBottom="40px"
              />
              <Button
                content="Submit"
                type="submit"
                fluid={true}
                loading={loading}
                disabled={loading || isSubmitting}
              />
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export const InquiryForm = withTheme(InquiryFormComponent)
