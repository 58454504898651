import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../../@types'
import { limitString } from '../../../../../util/util'

interface ICardContentProps {
  theme: ITheme
  title: string
  description: string
  path: string
  htmlContent?: boolean
  header?: boolean
}

const CardContentComponent: FC<ICardContentProps> = (props) => {
  const {
    theme,
    title,
    description,
    path,
    htmlContent = false,
    header = false,
  } = props

  return (
    <div
      css={css`
        margin-top: 9px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        ${!header && 'min-height: 28ex;'}
      `}
    >
      <div>
        <Link href={path} passHref>
          <a
            css={css`
              font-family: ${theme.fontSecondary};
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              letter-spacing: -0.02px;
              color: ${theme.black};
            `}
          >
            {limitString(title, 60)}
          </a>
        </Link>
      </div>
      <div
        css={css`
          margin-top: 8px;
          font-family: ${theme.fontPrimary};
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: ${theme.textSecondary};
          overflow-wrap: break-word;
        `}
      >
        {htmlContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: limitString(description, 330),
            }}
          />
        ) : (
          <label>{limitString(description, 330)}</label>
        )}
      </div>
    </div>
  )
}

export const CardContent = withTheme(CardContentComponent)
