import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Menu } from '../index'
import { ITheme } from '../../../../@types'

interface Props {
  theme: ITheme;
  title: string;
  description: string;
}
const AboutHeadComponent: FC<Props> = ({ theme, title, description }) => {
  return (
    <div
      css={css`
        min-height: '522px';
      `}
    >
      <Menu />
      <div
        css={css`
          margin: 100px 0px 92px 100px;
          display: flex;
          flex-direction: column;
          max-width: 925px;
          @media (max-width: 991px) {
            margin: 0px;
            margin-top: 100px;
            padding: 56px 16px 32px 16px !important;
          }
        `}
      >
        <h1
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.02px;
            color: ${theme.primary};
            word-wrap: break-word;
            margin: 0;
          `}
        >
          {title}
        </h1>
        <label
          css={css`
            margin-top: 24px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            color: ${theme.black};
            word-wrap: break-word;
          `}
        >
          {description}
        </label>
      </div>
    </div>
  )
}

export const AboutHead = withTheme(AboutHeadComponent)
