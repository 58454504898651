import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Grid } from 'semantic-ui-react'
import { IGallery, ITheme } from '../../../../@types'
import { getSlug } from '../../../../util/util'

interface IGalleryCardComponentProps {
  theme: ITheme
  spaceBottom?: string
  only: string
  data: IGallery
  fullWidth?: boolean
}

const GalleryCardComponent: FC<IGalleryCardComponentProps> = (props) => {
  const { theme, spaceBottom = '0px', fullWidth = false, data, only } = props
  return (
    <Grid.Column
      css={css`
        padding: 0px 50px ${spaceBottom} 0px !important;
        @media (max-width: 767px) {
          flex: 0 0 ${fullWidth ? '100%' : '90%'} !important;
        }
      `}
      only={only}
    >
      <Link href={`/media/gallery/${data.id}/${getSlug(data.title)}`} passHref>
        <a
          css={css`
            display: flex;
            flex-direction: column;
            cursor: pointer;
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
              transform: translate3d(0px, -1px, 0px);
            }
          `}
        >
          <div
            css={css`
              background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 66.32%,
                  ${theme.black} 100%
                ),
                url(${data.thumbnail});
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              min-height: 235px;
              border-radius: 4px;
            `}
          >
            <div
              css={css`
                padding: 0px 43.89px 16px 16px;
                display: flex;
                align-items: flex-end;
                min-height: 235px;
                background-color: transparent;
              `}
            >
              <label
                css={css`
                  font-family: ${theme.fontSecondary};
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: 0.5px;
                  color: ${theme.white};
                  cursor: pointer;
                  word-wrap: break-word;
                `}
              >
                {data.title}
              </label>
            </div>
          </div>
        </a>
      </Link>
    </Grid.Column>
  )
}

export const GalleryCard = withTheme(GalleryCardComponent)
