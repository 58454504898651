import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'
import { Grid } from 'semantic-ui-react'
import { map } from 'lodash'
import { FOOTER_ROUTES } from '../../../../constant'
import { SocialLinks } from './SocialLinks'
import { getMainRoute } from '../../../../util/util'

interface IFooterMobileProps {
  theme: ITheme
  navigation: string
  isNav?: boolean
}

const FooterMobileComponent: FC<IFooterMobileProps> = (props) => {
  const { theme, navigation, isNav=true } = props
  return (
    <Grid.Column
      only="mobile tablet"
      mobile={16}
      tablet={16}
      verticalAlign="middle"
      css={css`
        padding: 0 !important;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 64px 16px 0px 16px !important;
        `}
      >
        {isNav && map(FOOTER_ROUTES, (route, i) => {
          const mainRoute = getMainRoute(route.path)
          return (
            <div
              css={css`
                margin-top: 24px;
              `}
              key={i}
            >
              <Link href={route.path} passHref>
                <a
                  css={css`
                    font-family: ${theme.fontPrimary};
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    color: ${theme.black};
                    opacity: 0.75;
                    text-decoration: ${navigation === mainRoute
                      ? 'underline'
                      : 'none'};
                    font-weight: ${navigation === mainRoute
                      ? 'bold'
                      : 'normal'};
                    text-decoration-thickness: 2px;
                    cursor: pointer;
                    &:hover {
                      color: ${theme.black};
                      text-decoration: underline;
                      text-decoration-thickness: 2px;
                    }
                    -webkit-tap-highlight-color: transparent;
                  `}
                >
                  {route.title}
                </a>
              </Link>
            </div>
          )
        })}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          padding: 24px 16px 24px 16px !important;
        `}
      >
        <SocialLinks />
      </div>
      <div
        css={css`
          padding: 0px 16px 24px 16px !important;
        `}
      >
        {' '}
        <label
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: ${theme.black};
            opacity: 0.65;
          `}
        >
          Copyright © 2008-2013 Royal College Old Boys in Western Australia
          Association (RCOBAWA). All Rights Reserved.
        </label>
        <br />
        <span
          css={css`
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: ${theme.black};
            opacity: 0.65;
          `}
        >
          <label>Powered by</label>&nbsp;
          <Link href='https://www.humanisedtech.com/member-management/' passHref>
            <a css={css`color: ${theme.black}; text-decoration-line: underline; :hover{color: ${theme.black}; text-decoration-line: underline;}`} target="_blank" rel="noopener noreferrer">
              HumanisedTech
            </a>
          </Link>
        </span>
      </div>
    </Grid.Column>
  )
}

export const FooterMobile = withTheme(FooterMobileComponent)
