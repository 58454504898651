import React, { FC, useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Tab } from 'semantic-ui-react'
import { findIndex } from 'lodash'
import { ITheme } from '../../../../@types'

interface ITabPaneProps {
  menuItem?: string
  render?: () => React.ReactNode
  path: string
}

interface ITabContainerProps {
  panes: Array<ITabPaneProps>
  theme: ITheme
}

const TabContainerComponent: FC<ITabContainerProps> = (props) => {
  const [activeIndex, setIndex] = useState(0)
  const router = useRouter()
  const { panes, theme } = props

  useEffect(() => {
    setIndex(
      findIndex(panes, (o) => {
        return o.path === router.asPath
      })
    )
  }, [router])

  return (
    <Tab
      activeIndex={activeIndex}
      menu={{ secondary: true }}
      panes={panes}
      onTabChange={(e, data) => Router.push(data.panes[data.activeIndex].path)}
      css={css`
        .menu {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-height: 60px;
          margin: 0 !important;
          padding-left: 100px !important;
          background-color: ${theme.primary} !important;
          .item {
            height: 60px !important;
            display: flex;
            justify-content: center;
            height: 100%;
            padding: 0 !important;
            margin: 0 !important;
            border-radius: 0 !important;
            width: 190px !important;
            font-family: Manrope;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.02px;
            color: ${theme.white};
            &:hover {
              color: ${theme.white} !important;
            }
          }
          .active {
            background-color: ${theme.white} !important;
            color: ${theme.primary} !important;
            &:hover {
              color: ${theme.primary} !important;
            }
          }
        }
        @media (max-width: 991px) {
          .menu {
            flex-direction: column;
            padding: 0 !important;
            .item {
              width: 100% !important;
              align-self: unset !important;
            }
          }
        }
      `}
    />
  )
}

export const TabContainer = withTheme(TabContainerComponent)
