import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'
import BreadcrumbImg from '../assets/breadcrumb.svg'
import BreadcrumbArrowImg from '../assets/breadcrumb-arrow.svg'

interface IBreadcrumbProps {
  theme: ITheme
  location: string
}

const BreadcrumbComponent: FC<IBreadcrumbProps> = (props) => {
  const { theme, location } = props
  return (
    <div
      css={css`
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <Link href={'/'} passHref>
        <a
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
          `}
        >
          <BreadcrumbImg />
        </a>
      </Link>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: 17px;
        `}
      >
        <BreadcrumbArrowImg />
      </div>
      <label
        css={css`
          margin-left: 15px;
          font-family: ${theme.fontPrimary};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: ${theme.textSecondary};
        `}
      >
        {location}
      </label>
    </div>
  )
}

export const Breadcrumb = withTheme(BreadcrumbComponent)
