import axios from 'axios'
import { get } from 'lodash'
import { showError } from '../../../util/showError'
import { AppThunk } from '../../types'
import {
  EventTicketAction,
  GetEventValidatedAction,
} from './reducer'
import { EventTypes } from './types'

export const eventPurchaseLoadingAction = (
  loading: boolean
): EventTicketAction => ({
  type: EventTypes.TICKET_PURCHASE_LOADING,
  loading,
})

export const eventPurchaseErrorAction = (error: string): EventTicketAction => ({
  type: EventTypes.TICKET_PURCHASE_ERROR,
  error,
})

export const eventPurchaseSuccessAction = (
  pdfUrl: string | null
): EventTicketAction => ({
  type: EventTypes.TICKET_PURCHASE_SUCCESS,
  pdfUrl,
})

export interface ITicketPurchase {
  amount: number
  numberOfTickets: string
  eventId: string
  paymentMethodNonce?: string
  paymentMode: string
  paymentType: string
  guestFirstName?: string
  guestLastName?: string
  guestEmail?: string
  userId?: string
}

export const purchaseTicket =
  (data: ITicketPurchase): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(eventPurchaseSuccessAction(null))
      dispatch(eventPurchaseErrorAction(null))
      dispatch(eventPurchaseLoadingAction(true))
      const payment = await axios.post('/payment/items', data)
      const response = await axios.get(
        `/payment/getTickets/?itemPaymentId=${payment.data.itemPaymentId}&eventId=${data.eventId}`
      )
      dispatch(
        eventPurchaseSuccessAction(
          get(response, 'data.ticketPdf.ticketPdf', null)
        )
      )
    } catch (error) {
      const getError = showError(error)
      dispatch(eventPurchaseErrorAction(getError))
      throw getError
    } finally {
      dispatch(eventPurchaseLoadingAction(false))
    }
  }

export const getEventValidatedLoadingAction = (
  loading: boolean
): GetEventValidatedAction => ({
  type: EventTypes.GET_EVENT_VALID_LOADING,
  loading,
})

export const getEventValidatedErrorAction = (
  error: string
): GetEventValidatedAction => ({
  type: EventTypes.GET_EVENT_VALID_ERROR,
  error,
})

export const getEventValidatedSuccessAction = (
  eventValid: IValidEventResponse | null
): GetEventValidatedAction => ({
  type: EventTypes.GET_EVENT_VALID_SUCCESS,
  eventValid,
})

export interface IValidEvent {
  eventId: number
  userId?: string
}

export interface IValidEventResponse {
  accessValidation: boolean
  group: string
}

export const getEventValidation =
  (data: IValidEvent): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getEventValidatedSuccessAction(null))
      dispatch(getEventValidatedErrorAction(null))
      dispatch(getEventValidatedLoadingAction(true))
      const validation = await axios.post(`/payment/eventValidation`, data)
      dispatch(getEventValidatedSuccessAction(validation.data))
    } catch (error) {
      dispatch(getEventValidatedErrorAction(showError(error)))
    } finally {
      dispatch(getEventValidatedLoadingAction(false))
    }
  }
