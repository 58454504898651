import React, { FC } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../../../@types'
import JoinArrowImg from '../../../assets/join-arrow-primary.svg'
import TicketImg from '../../../assets/ticket.svg'

interface IViewMoreProps {
  theme: ITheme
  viewText: string
  path: string
  haveTickets?: boolean
  remainTickets?: number
  haveSeperator?: boolean
}

const ViewMoreComponent: FC<IViewMoreProps> = (props) => {
  const {
    theme,
    viewText = 'View More',
    haveTickets,
    remainTickets,
    path,
    haveSeperator = true,
  } = props
  return (
    <div>
      {haveSeperator && (
        <div
          css={css`
            margin-top: 16px;
            background: ${theme.bgGray};
            opacity: 0.25;
            border: 1px solid ${theme.stroke};
          `}
        />
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 17px;
        `}
      >
        <Link href={path} passHref>
          <a
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              @media (max-width: 991px) {
                align-items: center;
              }
            `}
          >
            <label
              css={css`
                margin-right: 10px;
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.25px;
                text-decoration-line: underline;
                color: ${theme.primary};
                cursor: pointer;
              `}
            >
              {viewText}
            </label>
            <JoinArrowImg />
          </a>
        </Link>
        {haveTickets && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              span {
                flex: 1;
                display: flex;
                label {
                  margin-left: 10px;
                  font-family: ${theme.fontPrimary};
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.25px;
                  color: ${theme.textSecondary};
                }
              }
            `}
          >
            <span>
              <TicketImg />
              <label>{remainTickets !== 0? `${remainTickets} Remaining` : 'Sold Out'} </label>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export const ViewMore = withTheme(ViewMoreComponent)
