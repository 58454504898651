import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Menu } from '../index'
import { ITheme } from '../../../../@types'

interface Props {
  theme: ITheme;
  title: string;
  description: string;
  haveTabs?: boolean
}
const DescriptionHeadComponent: FC<Props> = ({ theme, title, description, haveTabs=false }) => {
  return (
    <div
      css={css`
        min-height: ${haveTabs? '448px' : '504px'};
        background-color: ${theme.primary};
      `}
    >
      <Menu isInverted />
      <div
        css={css`
          padding: 128px 0px 0px 100px;
          display: flex;
          flex-direction: column;
          max-width: 850px;
          @media (max-width: 991px) {
            padding: 128px 16px 0px 16px !important;
          }
        `}
      >
        <h1
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.02px;
            color: ${theme.white};
            margin: 0;
          `}
        >
          {title}
        </h1>
        <label
          css={css`
            margin-top: 16px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            color: ${theme.white};
            @media (max-width: 767px) {
              margin-bottom: 94px!important;
            }
          `}
        >
          {description}
        </label>
      </div>
    </div>
  )
}

export const DescriptionHead = withTheme(DescriptionHeadComponent)
