import { useEffect, useState } from 'react'
import {
  isMobile as isMobileDevice,
  isTablet as isTabletDevice,
  isBrowser as isDesktopDevice,
} from 'react-device-detect'
const MOBILE_MAX_WIDTH = 760
const TABLET_MAX_WIDTH = 1024
const LAPTOP_MAX_WIDTH = 1440
const DESKTOP_MAX_WIDTH = 2000
export function useWindowSize(): {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isDesktopLarge: boolean
} {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return {
    isMobile: isMobileDevice || windowSize.width < MOBILE_MAX_WIDTH,
    isTablet:
      isTabletDevice ||
      (windowSize.width > MOBILE_MAX_WIDTH &&
        windowSize.width < TABLET_MAX_WIDTH),
    isDesktop:
      isDesktopDevice ||
      (windowSize.width > TABLET_MAX_WIDTH &&
        windowSize.width < LAPTOP_MAX_WIDTH),
    isDesktopLarge:
      windowSize.width > LAPTOP_MAX_WIDTH &&
      windowSize.width < DESKTOP_MAX_WIDTH,
  }
}
