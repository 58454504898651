import React, { FC, 
  // useState 
} from 'react'
import { css, withTheme } from '@emotion/react'
import { Image } from 'semantic-ui-react'
import { ITheme } from '../../../../@types'
import { SectionContainer } from '../SectionContainer'
import {
  CardContainer,
  CommitteeCard,
  PastPresidentCard,
} from '../../components'
import { COMMITTEE, PASTPRESIDENTS,
  //  PAST_PRESIDENTS 
  } from '../../static'

interface Props {
  theme: ITheme
}

const AboutContentComponent: FC<Props> = ({ theme }) => {
  // const [content, setContent] = useState(false)

  const textStyle = `
    font-family: ${theme.fontPrimary};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;

    color: ${theme.black};

    `
  return (
    <div
      css={css`
        margin-top: 80px;
        margin-bottom: 131px;
        @media (max-width: 991px) {
          margin-top: 32px;
          margin-bottom: 64px;
        }
      `}
    >
      <div
        css={css`
          margin: 0px 100px;
          display: flex;
          flex-direction: column;
          max-width: 925px;
          @media (max-width: 991px) {
            margin: 0px 16px;
          }
        `}
      >
        <label
          css={css`
            ${textStyle}
          `}
        >
          The foremost objectives of our association are to promote fellowship
          amongst members and their families, and visiting old Royalists, and to
          raise funds for the benefit and advancement of Royal College, and for
          any other charitable purpose, and to invest and deal with the funds so
          raised in the best possible manner.
        </label>
        <span
          css={css`
            margin-top: 80px;
            display: flex;
            @media (max-width: 991px) {
              display: block;
            }
          `}
        >
          <Image
            src={require('../../assets/about-logo.svg')}
            css={css`
              width: 118px;
              height: 158px;
              float: left;
              margin: 0 20px 20px 0;
              pointer-events: none;
            `}
          />
          <label
            css={css`
              ${textStyle}
            `}
          >
            The current RCOBAWA was formed by a few friends, in July
            2017. As a result of their hard work and dedication, an inaugural
            meeting was staged at the South Perth Foreshore, followed by
            a barbeque. It was then decided to register this group as an
            association, and thus was born RCOBAWA (in 2018). The OBA was incorporated as RCOBAWA under the
            Associations Incorporation Act in 2018, and has continued to
            flourish since then, mainly due to the dedication of its members and
            their families.
          </label>
        </span>
        <label
          css={css`
            ${textStyle}
            margin-top: 80px;
          `}
        >
          In keeping with the association’s main aim of fostering fellowship
          amongst the growing number of old Royalists in Western Australia,
          RCOBAWA has been upholding the traditions and spirit of our old school
          in hosting an array of annual events such as the Perth version of the
          Roy-Tho, a get-together for the Bradby Shield, RC Christmas party, to
          name but a few. Members and their families have been gracing these
          events in generous numbers in the past, and it is envisaged the
          numbers will only go north.
        </label>
        <label
          css={css`
            ${textStyle}
            margin-top: 80px;
          `}
        >
          The annual general meeting of the association is typically held in
          July of each year.
        </label>
      </div>

      <SectionContainer
        containerPaddingTop="120px"
        heading={
          // content ? 'RCOBAWA Past Presidents' : 
          'RCOBAWA Committee'}
        // viewMoreLabel={
        //   content ? 'View Current Committee' : 'View Past Presidents'
        // }
        // viewMoreToggle={() => setContent(!content)}
        content={
          // content ? (
          //   <CardContainer
          //     containerMarginTop="64px"
          //     columns={2}
          //     dataSet={PAST_PRESIDENTS}
          //     Card={PastPresidentCard}
          //     emptyMessage="No details for now. Please check later."
          //     spaceBottom="36px"
          //   />
          // ) : 
          (
            <>
              <CardContainer
                containerMarginTop="64px"
                stackable={false}
                columns={4}
                dataSet={COMMITTEE}
                Card={CommitteeCard}
                emptyMessage="No details for now. Please check later."
                spaceBottom="48px"
              />
              <CardContainer
                containerMarginTop="64px"
                columns={2}
                dataSet={
                  
                  PASTPRESIDENTS
                 }
                Card={PastPresidentCard}
                emptyMessage="No details for now. Please check later."
                spaceBottom="36px"
              />
            </>
          )
        }
      />
    </div>
  )
}

export const AboutContent = withTheme(AboutContentComponent)
