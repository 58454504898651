import React, { FC, useState } from 'react'
import { useField } from 'formik'
import { omit } from 'lodash'
import { css } from '@emotion/react'
import { Form, FormInput as SemanticInput, Icon, InputProps } from 'semantic-ui-react'
import { withTheme } from '@emotion/react'
import { ITheme } from '../../../@types'

interface JInputProps extends InputProps{
  theme: ITheme
  marginBottom: string
}

const PasswordInputComponent: FC<JInputProps> = (props) => {
  const [view, showPass] = useState(false)
  const { theme, marginBottom } = props;
  const [field, meta] = useField(props.name) // field is the same, meta contains field-level slices of state
  const hasError = meta.touched && meta.error;
  return (
    <Form.Field
      css={css`
        margin-bottom: ${marginBottom} !important;       
        label{
          display: flex !important;
        }
      `}
    >
      <label
        css={css`
          font-family: ${theme.fontPrimary} !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 24px !important;
          color: ${theme.black} !important;
        `}
      >
        {props.label}
      </label>
      <SemanticInput
        type={view ? 'text' : 'password'}
        css={css`
          margin: 0 !important;   
          input {
            height: 48px !important;
            border: 1px solid ${hasError? theme.error : theme.stroke} !important;
            box-sizing: border-box !important;
            border-radius: 4px !important;
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 24px !important;
            background-color: ${theme.white} !important;
            ::placeholder {
              color: ${theme.textSecondary} !important;
              opacity: 1; /* Firefox */
            }        
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
             color: ${theme.textSecondary} !important;
            }        
            ::-ms-input-placeholder { /* Microsoft Edge */
             color: ${theme.textSecondary} !important;
            }
          }
        `}
        {...field}
        {...omit(props, ['label', 'theme', 'marginBottom'])}
        error={meta.touched && Boolean(meta.error)}         
        icon={
          <Icon
            name={view ? 'eye slash' : 'eye'}
            link
            onClick={() => showPass(!view)}
          />
        }
      />
      {hasError && (
        <div
          css={css`
            font-family: ${theme.fontPrimary} !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 20px !important;
            color: ${theme.error} !important;
            margin-top: 4px !important;
            text-align: left !important;
          `}
        >
          {meta.error}
        </div>
      )}
    </Form.Field>
  )
}

export const PasswordInput = withTheme(PasswordInputComponent)