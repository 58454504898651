import React, { FC } from 'react'
import { filter, map, take } from 'lodash'
import {
  SinglePageHead,
  Footer,
  SectionContainer,
  CardContainer,
  GalleryCard,
  JoinNowContainer,
  ImageGallery,
} from '../components'
import { ISiteData } from '../../../@types/site'

export const GalleryPage: FC<ISiteData> = ({
  galleryList,
  gallery,
  albumList,
}) => {
  const otherGallery = filter(galleryList, (n) => n.id !== gallery.id)

  return (
    <div>
      <SinglePageHead />
      <main>
        <SectionContainer
          containerPaddingTop="83px"
          containerPaddingBottom="100px"
          location="Media"
          heading={gallery.title}
          singlePage
          content={
            <ImageGallery
              images={map(albumList, (e) => ({
                src: e.src,
                width: 16,
                height: 9,
              }))}
            />
          }
        />
        <SectionContainer
          inverted
          containerPaddingTop="100px"
          containerPaddingBottom="115px"
          heading="View More"
          content={
            <CardContainer
              containerMarginTop="64px"
              columns={3}
              dataSet={take(otherGallery, 3)}
              Card={GalleryCard}
              fullWidth
              emptyMessage="No galleries found. Please check later."
            />
          }
        />
        <JoinNowContainer
          title="Join the Premier Alumni Association of our Alma Mater, Royal College."
          description="Relive the memories of your youth beautifully-spent at that hallowed institution along Reid Avenue, as you enter a new chapter in your life. Become a member and connect, grow and keep the spirit alive."
        />
      </main>
      <Footer />
    </div>
  )
}
