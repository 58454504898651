import React, { FC } from 'react'
import { css, withTheme } from '@emotion/react'
import moment from 'moment'
import { ITheme } from '../../../../@types'
import { SocialLinks } from '../Footer/SocialLinks'
import CalenderImg from '../../assets/calender-white.svg'
import LocationPinImg from '../../assets/location-white.svg'

interface IOverlayHeadingProps {
  theme: ITheme
  heading: string
  description?: string
  emailTo?: string
  socialLinks?: boolean
  date?: string
  time?: string
  location?: string
}

const OverlayHeadingComponent: FC<IOverlayHeadingProps> = (props) => {
  const { theme, heading, description, emailTo, socialLinks, date, time, location } = props
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 160px 210px 0px 101px;
      `}
    >
      <div
        css={css`
          max-width: 505px;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: -0.02px;
            color: ${theme.white};
            word-wrap: break-word;
          `}
        >
          {heading}
        </label>
      </div>
      {description && (
        <div
          css={css`
            margin-top: 16px;
            max-width: 505px;
          `}
        >
          <label
            css={css`
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;
              color: ${theme.white};
              word-wrap: break-word;
            `}
          >
            {description}
          </label>
        </div>
      )}
      {date && time && (
        <span
          css={css`
            display: flex;
            align-items: center;
            margin-top: 27px;
          `}
        >
          <CalenderImg />
          <div
            css={css`
              margin-left: 12px;
            `}
          >
            <label
              css={css`
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: ${theme.white};
              `}
            >
              {moment(date).format('DD-MM-YYYY')} {moment(time, 'HH:mm:ss').format('h:mmA')}
            </label>
          </div>
        </span>
      )}
      {location && (
        <span
          css={css`
            display: flex;
            align-items: center;
            margin-top: 30px;
          `}
        >
          <LocationPinImg />
          <div
            css={css`
              margin-left: 12px;
            `}
          >
            <label
              css={css`
                font-family: ${theme.fontPrimary};
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: ${theme.white};
              `}
            >
              {location}
            </label>
          </div>
        </span>
      )}
      {emailTo && (
        <a
          href={`mailto:${emailTo}?subject=Contact RCOBAWA`}
          css={css`
            margin-top: 16px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            text-decoration-line: underline;
            color: ${theme.white};
            cursor: pointer;
            &:hover {
              color: ${theme.white};
              text-decoration-line: underline;
            }
          `}
        >
          {emailTo}
        </a>
      )}
      {socialLinks && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 21px;
          `}
        >
          <SocialLinks white />
        </div>
      )}
    </div>
  )
}

export const OverlayHeading = withTheme(OverlayHeadingComponent)
