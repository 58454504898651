import React, { FC } from 'react'
import { css, withTheme } from '@emotion/react'
import { ITheme } from '../../../../@types'
import { Icon, Image } from 'semantic-ui-react'
import ContactImg from '../../assets/contact.png'
import AddressImg from '../../assets/address.png'

interface Props {
  theme: ITheme
}

const TermsContentComponent: FC<Props> = ({ theme }) => {
  const textStyleTitle = `
  font-family: ${theme.fontSecondary};
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;  
  letter-spacing: -0.02px;
  color: ${theme.primary};
  `

  const textStyleDescription = `
    margin-top: 24px;
    font-family: ${theme.fontPrimary};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: ${theme.black};
    white-space: pre-line;
    `

  const Content = ({ title, description }) => (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 64px;
      `}
    >
      <label
        css={css`
          ${textStyleTitle}
        `}
      >
        {title}
      </label>
      <span
        css={css`
          ${textStyleDescription}
        `}
      >
        {description}
      </span>
    </div>
  )
  return (
    <div
      css={css`
        margin-bottom: 120px;
        @media (max-width: 991px) {
          margin-top: 32px;
          margin-bottom: 64px;
        }
      `}
    >
      <div
        css={css`
          margin: 0px 100px;
          display: flex;
          flex-direction: column;
          max-width: 925px;
          @media (max-width: 991px) {
            margin: 0px 16px;
          }
        `}
      >
        <Content
          title="Terminology"
          description="In these Terms of Use, references to ‘RCOBAWA‘ ,'we', 'us' and 'our' mean the ROYAL COLLEGE OLD BOYS ASSOCIATION WESTERN AUSTRALIA . ‘Article providers’ means authors, publishers, presenters, photographers and videographers who send materials to WWW.RCOBAWA.ORG.AU"
        />
        <Content
          title="Amendments to terms of use"
          description="We reserve the right to amend these Terms of Use from time to time. Amendments to these Terms of Use will be effective immediately upon notification on this Web Site. Your continued use of this Web Site following such notification will represent your agreement to be bound by these Terms of Use as amended."
        />
        <Content
          title="Disclaimer"
          description="We do not accept responsibility for any action, claim, demand, loss (including consequential loss) and/or cost (including legal cost) however caused (including through negligence and/or wilful misconduct) which you may directly and/or indirectly suffer and/or incur in connection with your use of and/or reliance on the contents of the Website."
        />
        <Content
          title="Specific warnings"
          description={`Whilst we have no reason to believe that any information contained on the Web Site is inaccurate, we do not warrant the accuracy, adequacy and/or completeness of that information, nor do we undertake to keep the Web Site updated.\n\nIntellectual property rights in the Web Site are owned and/or licensed by us. Governing law\n\nThese Terms of Use will be governed by and construed according to the laws of the State of Western Australia and the Commonwealth of Australia. You irrevocably submit to the exclusive jurisdiction of the courts of the State of Western Australia and the Commonwealth of Australia.`}
        />
        <Content
          title="General"
          description="We accept no liability for any failure to comply with these Terms of Use where that failure is due to circumstances beyond our control. If we waive any rights available to us under these Terms of Use on one occasion, this does not mean that those rights will automatically be waived on any other occasion. If any of these Terms of Use are held to be invalid, unenforceable and/or illegal for any reason, the remaining Terms of Use will nevertheless continue in full force."
        />
        <Content
          title="Privacy collection statement"
          description={
            <p>
              We collect this information for the purpose of:
              <br />
              <br />
              <ol>
                <li>registering you as a member of RCOBAWA;</li>
                <li>
                  keeping YOU INFORMED ABOUT FORTHCOMING ACTIVITIES AND EVENTS.
                </li>
                <li>
                  inform us if you do not wish us to use or store your personal
                  information;
                </li>
                <li>
                  analysing our services and MEMBERSHIP needs with a view to
                  developing new and/or improved services;
                </li>
              </ol>
              <br />
              If the personal information you provide to us is incomplete and/or
              inaccurate, we may be unable to process your registration and/or
              be unable to provide you with the services you are seeking.
            </p>
          }
        />
        <Content
          title="Payment processing"
          description={
            <>
              <p>
                We do not receive or store any credit card information. All
                credit card information is processed securely by the Merchant
                Facility.
              </p>
              <br />
              <p>
                <i>“Electronic Transaction”</i> means an electronic transaction
                conducted through the Majestri Platform, including a payment
                made through any of the following methods:
                <br />
                <br />
                <ul>
                  <li>Credit Card;</li>
                  <li>Debit Card;</li>
                  <li>Prepaid payment card; or </li>
                  <li>BPay payment.</li>
                </ul>
              </p>
            </>
          }
        />
        <Content
          title="Refund and cancellation policy"
          description="No refunds and cancellations after doing the payment through this platform. If you need a refund or a purchase cancellation due to an unavoidable reason, you should contact our admins through rcobawa@gmail.com. As a general rule, all ticket sales are final and non-refundable. If the event is cancelled, refund procedures will be announced by organizers."
        />
        <Content
          title="Changes to the terms and conditions"
          description="We will inform you if there are any modifications in terms and conditions through our platform with a prior notice. You acknowledge and agree to the condition that we have the right to terminate your access as a member if you try to go against our community rules."
        />
        <Content
          title="Contact us"
          description={
            <>
              <p>
                Don’t hesitate to contact us if you have any more questions.
              </p>
              <br />
              <div
                css={css`
                  p{
                    display:flex;
                    flex-direction: row;
                    align-items: center;
                    img{
                      margin-right: 10px;
                    }
                  }
                `}
              >
                <p><Image src={ContactImg} /> +61 426 264 671</p>
                <p><Image src={AddressImg} /> 4 Kersley Loop, Harrisdale WA 6112</p>
              </div>
            </>
          }
        />
      </div>
    </div>
  )
}

export const TermsContent = withTheme(TermsContentComponent)
