import React, { FC, ReactElement } from 'react'
import { css, withTheme } from '@emotion/react'
import { Segment, Image } from 'semantic-ui-react'
import LandingImage from '../../assets/landing-poster.webp'
import { ITheme } from '../../../../@types'

interface ISponsorshipContainerProps {
  theme: ITheme
  children?: ReactElement | undefined
}

const SponsorshipContainerComponent: FC<ISponsorshipContainerProps> = (
  props
) => {
  const { theme, children } = props

  return (
    <Segment
      style={{
        //do not remove these even its written in style prop
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
      }}
      basic
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          height: 100%;
          width: 100%;
          min-height: 100vh;
          background-color: white;
          @media (max-width: 991px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            background: linear-gradient(
                0deg,
                rgba(16, 39, 112, 0.9),
                rgba(16, 39, 112, 0.9)
              ),
              url(${LandingImage});
            background-repeat: no-repeat;
            background-size: cover;
            width: 56%;
            padding: 60px 80px 60px 100px;
            @media (max-width: 991px) {
              width: 100% !important;
              padding: 32px 16px !important;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div>
              <Image
                src={require('../../assets/sponsorship-logo.png')}
                css={css`
                  pointer-events: none;
                `}
              />
            </div>
            <div
              css={css`
                margin-top: 40px;
                span {
                  display: flex;
                  flex-direction: row;
                }
                .title {
                  font-family: ${theme.fontSecondary};
                  font-style: normal;
                  font-weight: 800;
                  font-size: 40px;
                  line-height: 48px;
                  /* identical to box height, or 120% */
                  letter-spacing: -0.02px;
                  /* white */
                  color: ${theme.white};
                }
                .extra-color {
                  color: ${theme.secondary};
                }
                @media (max-width: 991px) {
                  .title {
                    line-height: 32px;
                  }
                  span {
                    flex-direction: column;
                  }
                }
              `}
            >
              <span>
                <label className="title">RCOBAWA</label>&nbsp;&nbsp;&nbsp;
                <label className="title extra-color">Sponsorship</label>
              </span>
            </div>
            <div
              css={css`
                margin-top: 16px;
                max-width: 630px;
                .title {
                  font-family: ${theme.fontPrimary};
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  line-height: 26px;
                  /* white */
                  color: ${theme.white};
                }
                @media (max-width: 991px) {
                  margin-top: 24px;
                }
              `}
            >
              <label className="title">
                Thank you for being on board with us. As our valued sponsor you
                will get maximum exposure via all our social media platforms
                throughout the event as well as pre and post event marketing.
                Your sponsorship is greatly appreciated.
              </label>
            </div>
            <div
              css={css`
                margin-top: 16px;
                max-width: 630px;
                .signature {
                  font-family: ${theme.fontPrimary};
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  line-height: 26px;
                  /* white */
                  color: ${theme.white};
                }
                @media (max-width: 991px) {
                  margin-top: 19px;
                }
              `}
            >
              <label className="signature">
                RCOBAWA Committee
              </label>
            </div>
          </div>
        </div>
        <div
          css={css`
            width: 44%;
            background-color: ${theme.white};
            overflow: auto;
            @media (max-width: 991px) {
              width: 100%;
            }
            @media (max-width: 760px) {
              ::-webkit-scrollbar {
                width: 0;
              }
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              padding: 28px 100px 28px 105px;
              @media (max-width: 991px) {
                width: 100%;
                align-items: center;
              }
              @media (max-width: 767px) {
                padding: 28px 16px 56px 16px !important;
                align-items: unset;
              }
            `}
          >
            {children && React.cloneElement(children)}
          </div>
        </div>
      </div>
    </Segment>
  )
}

export const SponsorshipContainer = withTheme(SponsorshipContainerComponent)
