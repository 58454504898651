import React, { FC } from 'react'
import {
  CardContainer,
  DescriptionHead,
  Footer,
  InfoContainer,
  MembershipCard,
  SectionContainer,
} from '../components'
import { ISiteData } from '../../../@types/site'

export const MembershipPage: FC<ISiteData> = ({
  membershipsList,
}) => {
  return (
    <div>
      <DescriptionHead
        title="RCOBAWA Membership"
        description="When you join RCOBAWA, you enter a prestigious club with centuries-old traditions honouring our Alma Mater, Royal College. Pick from a variety of membership options, make your payment, and become a member right here!"
      />

      <main>
        <SectionContainer
          containerPaddingTop="80px"
          containerPaddingBottom="40px"
          heading="Start your membership today"
          content={
            <CardContainer
              containerMarginTop="64px"
              columns={3}
              dataSet={membershipsList}
              Card={MembershipCard}
              spaceBottom="32px"
              emptyMessage="No membership types for now. Please check later."
            />
          }
        />
        <InfoContainer
          containerMarginBottom="120px"
          message="* Membership in the Association enables you to help your alma mater to the extent that the Association from time to time makes contributions to various projects to assist the school. In addition, the Association organizes a number of social events during the year that foster a strong fellowship amongst old Royalists living in Western Australia. Your membership also entitles you to the association’s quarterly newsletter Floreat, which can either be emailed or posted to you."
        />
      </main>
      <Footer />
    </div>
  )
}
