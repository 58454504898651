import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Menu } from '../index'

const SinglePageHeadComponent: FC = () => {
  return (
    <div>
      <Menu />
      <div
        css={css`
          @media (max-width: 991px) {
            margin-top: 56px;
          }
        `}
      />
    </div>
  )
}

export const SinglePageHead = withTheme(SinglePageHeadComponent)
