import { IMetaTags } from "../../@types";
import favicon from './public/favicon.ico'
import favicon16 from './public/favicon-16x16.png'
import favicon32 from './public/favicon-32x32.png'
import favicon96 from './public/favicon-96x96.png'
import manifest from './public/manifest.json'
import landingCoverWebp from './assets/landing-poster.webp'
import landingCoverJpg from './assets/landing-poster.jpg'
import AboutCoverWebp from './assets/about-cover.webp'
import AboutCoverJpg from './assets/about-cover.jpg'
import GroupPhotoWebp from './assets/group-photo.webp'
import GroupPhotoJpg from './assets/group-photo.jpg'

export const meta: IMetaTags = {
    favicon: {
        favicon,
        favicon16,
        favicon32,
        favicon96
    },
    manifest,
    site: "RCOBAWA",
    title: "RCOBAWA",
    description: "RCOBAWA is an alumni organisation in Western Australia that is dedicated to Old Boys of the alma mater, Royal College Colombo. Incorporated in Perth, membership is open to all past students of Royal College (RC) Colombo, Sri Lanka and the Junior School formerly known as Royal Primary School (RPS).",
    keywords: "RCOBAWA, Royal College Old Boys’ Association Western Australia, RCOBA, RCOBAA",
    image: {
        url: "https://rcobawa-prod-file-bucket.s3.ap-southeast-2.amazonaws.com/meta-image.webp",
        width: 1200,
        height: 630
    },
    preLoadImages: [
        landingCoverWebp,
        landingCoverJpg,
        AboutCoverWebp,
        AboutCoverJpg,
        GroupPhotoWebp,
        GroupPhotoJpg
    ]
};