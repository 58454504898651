import React, { FC } from 'react'
import { css } from '@emotion/react'
import { withTheme } from '@emotion/react'
import { Button, Grid, Image } from 'semantic-ui-react'
import { IMembership, ITheme } from '../../../../@types'
import { OverlayModal } from '../Overlay'
import { SignUpSteps } from '../Forms'

interface IMembershipCardComponentProps {
  theme: ITheme
  spaceBottom?: string
  only: string
  data: IMembership
}

const MembershipCardComponent: FC<IMembershipCardComponentProps> = (props) => {
  const { spaceBottom = '0px', data, theme, only } = props
  return (
    <Grid.Column
      css={css`
        padding: 0px 50px ${spaceBottom} 0px !important;
      `}
      only={only}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          background: ${theme.bgGray};
          border-radius: 4px;
          padding: 32px 24px 24px 28px;
        `}
      >
        <label
          css={css`
            font-family: ${theme.fontSecondary};
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.02px;
            color: ${theme.primary};
            word-wrap: break-word;
          `}
        >
          {data.title}
        </label>
        <label
          css={css`
            margin-top: 24px;
            font-family: ${theme.fontPrimary};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: ${theme.textSecondary};
          `}
        >
          Requirements
        </label>
        <div
          css={css`
            margin-top: 17px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            cursor: pointer;
            @media (max-width: 991px) {
              align-items: flex-start;
            }
          `}
        >
            <Image
              src={require('../../assets/condition.svg')}
              css={css`
                width: 24px;
                height: 24px;
              `}
            />
          <label
            css={css`
              display: flex;
              flex: 1;
              align-items: flex-start;
              margin-left: 10.89px;
              font-family: ${theme.fontPrimary};
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 24px;
              color: ${theme.black};
              height: 48px;
            `}
          >
            {data.condition}
          </label>
        </div>
        <div
          css={css`
            margin-top: 40.93px;
            margin-bottom: 32px;
            border: 1.0891px dashed ${theme.stroke};
          `}
        />
        <span>
          <label
            css={css`
              font-family: ${theme.fontSecondary};
              font-style: normal;
              font-weight: 800;
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.02px;
              color: ${theme.black};
            `}
          >
            ${data.amount}
          </label>
          <label
            css={css`
              font-family: ${theme.fontSecondary};
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 40px;
              letter-spacing: -0.02px;
              color: ${theme.textSecondary};
            `}
          >
            /Per annum
          </label>
        </span>
        <div
          css={css`
            margin-top: 48px;
          `}
        >
          <OverlayModal
            trigger={
              <Button
                css={css`
                  height: 60px;
                  font-family: ${theme.fontPrimary} !important;
                  font-style: normal !important;
                  font-weight: normal !important;
                  font-size: 20px !important;
                  line-height: 28px !important;
                  text-align: center !important;
                  color: ${theme.primary} !important;
                  background-color: #e2e9ff !important;
                  border-radius: 4px !important;
                  &:hover {
                    border: 1px solid ${theme.primaryDark};
                    box-sizing: border-box;
                  }
                `}
                fluid={true}
              >
                Join Now
              </Button>
            }
            heading="Royal College Old Boys' Association Western Australia"
            description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
          >
            <SignUpSteps initialForm="stepOne" membership={data.id} />
          </OverlayModal>
        </div>
      </div>
    </Grid.Column>
  )
}

export const MembershipCard = withTheme(MembershipCardComponent)
