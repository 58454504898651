import React, { FC } from 'react'
import { ISiteData } from '../../../@types/site'
import { OverlayModal } from '../components'
import { MembershipRenewForm } from '../components/Forms/Membership/MembershipRenew'



export const RenewPaymentPage: FC<ISiteData> = ({ expiredUser }) => {
    return (
        <OverlayModal
            heading="Royal College Old Boys' Association Western Australia"
            description="Royal College Old Boys' Association Western Australia is an organisation incorporated in the state of Western Australia."
            duration={0}
        >
            <MembershipRenewForm expiredUser={expiredUser} />
        </OverlayModal>
    )
}