import React, { FC, useEffect, useState } from 'react'
import { ForgotPasswordForm } from './ForgotPassword'
import { SignInForm } from './SignIn'
import { SignUpSteps } from './SignUp'

interface IFormContainer {
  initialForm: string
}

export const FormChanger: FC<IFormContainer> = (props) => {
  const { initialForm } = props
  const [form, setForm] = useState(null)
  const [membershipType, setMembershipType] = useState(null)

  useEffect(() => {
    if (initialForm) {
      setForm(initialForm)
    }
  }, [])

  switch (form) {
    case 'signIn':
      return <SignInForm setForm={(type) => setForm(type)} setMembershipType={(type) => setMembershipType(type)} />
    case 'forgotPassword':
      return <ForgotPasswordForm setForm={(type) => setForm(type)} />
    case 'signUp':
      return <SignUpSteps initialForm="stepOne" />
    case 'renewMembership':
      return <SignUpSteps renew initialForm="memberShipPayment" membership={membershipType} />
    default:
      return null
  }
}
