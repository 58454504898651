// Static Data
export const COMMITTEE = [
  {
    webp: require('./assets/committee/CharithaPattiaratchi.webp'),
    jpg: require('./assets/committee/CharithaPattiaratchi.jpg'),
    name: 'Professor Charitha Pattiaratchi',
    position: 'Patron',
  },
  {
    webp: require('./assets/committee/PrasadKumarasinghe.webp'),
    jpg: require('./assets/committee/PrasadKumarasinghe.jpg'),
    name: 'Professor Prasad Kumarasinghe',
    position: 'Patron',
  },
  {
    name: 'MR Nihal Kodithuwakku',
    position: 'Patron',
    webp: require('./assets/committee/Niahl-Kodituwakku.webp'),
    jpg: require('./assets/committee/Niahl-Kodituwakku.jpg'),
  },
  // {
  //   name: 'MR Nihal Kodithuwakku',
  //   position: 'Patron',
  // },
  {
    webp: require('./assets/committee/JeevanTennekoon.webp'),
    jpg: require('./assets/committee/JeevanTennekoon.jpg'),
    name: 'Jeevan Tennekoon',
    position: 'President',
  },
  {
    webp: require('./assets/committee/KapilaAbeysuriya.webp'),
    jpg: require('./assets/committee/KapilaAbeysuriya.jpg'),
    name: 'Kapila Abeysuriya',
    position: 'Vice President',
  },
  {
    webp: require('./assets/committee/KalinduMuthucumarana.webp'),
    jpg: require('./assets/committee/KalinduMuthucumarana.jpg'),
    name: 'Kalindu Muthucumarana',
    position: 'Vice President',
  },
  {
    webp: require('./assets/committee/RusiruKankanamge.webp'),
    jpg: require('./assets/committee/RusiruKankanamge.jpg'),
    name: 'Rusiru Kankanamge',
    position: 'Treasurer',
  },
  {
    webp: require('./assets/committee/ChamindaDias.webp'),
    jpg: require('./assets/committee/ChamindaDias.jpg'),
    name: 'Chaminda Dias',
    position: 'Secretary',
  },
  // {
  //   webp: require('./assets/committee/IndikaGunathilaka.webp'),
  //   jpg: require('./assets/committee/IndikaGunathilaka.jpg'),
  //   name: 'Indika Gunathilaka',
  //   position: 'Committee Member'
  // },
  {
    webp: require('./assets/committee/AbhayaSenanayake.webp'),
    jpg: require('./assets/committee/AbhayaSenanayake.jpg'),
    name: 'Abhaya Senanayake',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/ManjulaImaduwa.webp'),
    jpg: require('./assets/committee/ManjulaImaduwa.jpg'),
    name: 'Manjula Imaduwa',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/RathsaraChandrasekara.webp'),
    jpg: require('./assets/committee/RathsaraChandrasekara.jpg'),
    name: 'Rathsara Chandrasekara',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/IsuruDharmaratne.webp'),
    jpg: require('./assets/committee/IsuruDharmaratne.jpg'),
    name: 'Isuru Dharmaratne',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/ShanDissanayake.webp'),
    jpg: require('./assets/committee/ShanDissanayake.jpg'),
    name: 'Shan Dissanayake',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/David-R-S-Selvendra.webp'),
    jpg: require('./assets/committee/David-R-S-Selvendra.jpg'),
    name: 'David R S Selvendra',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/Ahlaam-Malik.webp'),
    jpg: require('./assets/committee/Ahlaam-Malik.jpg'),
    name: 'Ahlaam Malik',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/Thiwanka-Samaraweera.webp'),
    jpg: require('./assets/committee/Thiwanka-Samaraweera.jpg'),
    name: 'Thivanka Samaraweera',
    position: 'Committee Member'
  },
  {
    webp: require('./assets/committee/Rukshan-Jayawardena.webp'),
    jpg: require('./assets/committee/Rukshan-Jayawardena.jpg'),
    name: 'Rukshan Jayawardena',
    position: 'Committee Member'
  },
]
export const PASTPRESIDENTS =  [
  
]
// export const PAST_PRESIDENTS = [
//   {
//     name: 'Indika Gunathilaka',
//     year: '2020/21',
//   },
//   {
//     name: 'Rathsara Chandrasekara',
//     year: '2019/20',
//   },
//   {
//     name: 'Indika Gunathilaka',
//     year: '2018/19',
//   },
//   {
//     name: 'Indika Gunathilaka',
//     year: '2017/18',
//   },
// ]
